import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import axios from "axios";
import { APIURL } from "../asset/host";
import { setLoginStatus } from "../redux/actions";
import { useDispatch } from "react-redux";

export function Register() {
  const [psdMatchMsg, setpsdMatchMsg] = useState("");
  const [psdStrongMsg, setpsdStrongMsg] = useState("");
  const [password, setPassword] = useState("");
  const [psdMatch, setPsdMatch] = useState(false);
  const [psdStrong, setPsdStrong] = useState(false);

  const [error, setError] = useState("");
  const handlePassword = (event) => {
    const psd = event.target.value;
    const format = /[ `!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]/;
    if (psd.length > 6 && /\d/.test(psd) && format.test(psd)) {
      setPsdStrong(true);
      setPassword(psd);
      setpsdStrongMsg("your password is very strong");
    } else if (psd.length > 8) {
      setPsdStrong(true);
      setPassword(psd);
      setpsdStrongMsg("your password is good but not strong");
    } else if (psd.length > 6 && /\d/.test(psd)) {
      setPsdStrong(true);
      setPassword(psd);
      setpsdStrongMsg("your password is good but slightly strong");
    } else {
      setpsdStrongMsg("your password is very weak");
    }
  };
  const handlePsdConfirmation = (event) => {
    const cpsd = event.target.value;
    if (cpsd === password) {
      setpsdMatchMsg("Password Matched");
      setPsdMatch(true);
      handleChange(event);
      setpsdMatchMsg("Password Matched");
    } else {
      setpsdMatchMsg("Password not Match");
    }
  };

  const [input, setInput] = useState({});
  const [msg, setMsg] = useState("");
  const [status, setStatus] = useState("");
  const [startAlerting, setStartAlerting] = useState(false);
  const [usernameAvailable, setUsernameAvailable] = useState();
  const navigate = useNavigate();

  const handleUsernameChange = (event) => {
    setStartAlerting(true);
    if (event.target.value) {
      axios
        .post(`${APIURL}/api/users/checkUsernameAvailability`, {
          username: event.target.value,
        })
        .then((res) => {
          setUsernameAvailable(false);
          if (res.data.available) {
            setUsernameAvailable(true);
          } else {
            setUsernameAvailable(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setStartAlerting(false);
    }
    handleChange(event);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInput((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post(`${APIURL}/api/users`, input, { withCredentials: true })
      .then(function (response) {
        setError(null);
        setMsg(response.data.message);
        setStatus(response.data.status);
        setTimeout(function () {
          if (response.data.role === "seller") {
            navigate("/" + response.data.username);

            window.location.reload();
          } else if (response.data.role === "admin") {
            navigate("/dashboard");

            window.location.reload();
          } else {
            navigate("/buyer");

            window.location.reload();
          }
        }, 1000);
      })
      .catch(function (error) {
        // console.error(error);
        // console.log(error.response.data.error);
        setError(error.response.data.error + " try later");
      });
  };

  return (
    <>
      <h1 className="Header-short-line">5gada</h1>
      <div>
        <form
          method="POST"
          className="registration-form"
          onSubmit={handleSubmit}
        >
          <div>
            <label>create username:</label>
            <input
              name="username"
              required
              type="text"
              onChange={handleUsernameChange}
            />
            <br />
            {startAlerting ? (
              <>
                {" "}
                {usernameAvailable ? (
                  <span className="alert-success">Available</span>
                ) : (
                  <sp className="alert-danger">Not available</sp>
                )}
                <br /> <br />
              </>
            ) : (
              <></>
            )}
          </div>
          <div>
            <label>Full Name:</label>
            <input
              name="fullName"
              required
              type="text"
              onChange={handleChange}
            />
          </div>
          <div>
            <label>Phone Number:</label>
            <input
              name="phoneNumber"
              required
              type="tel"
              onChange={handleChange}
            />
          </div>
          {/* <div>
            <label>Email:</label>
            <input name="email"  type="email" onChange={handleChange} />
          </div> */}
          <div>
            <label>User Type:</label>
            <select name="userType" required onChange={handleChange}>
              <option value="">Select</option>
              <option value="seller">Seller</option>
              <option value="buyer">Buyer</option>
              <option value="both">Both</option>
            </select>
          </div>

          <div>
            <label>Password:</label>
            <input
              name="cpassword"
              required
              type="password"
              onChange={handlePassword}
            />
            <br />
            <span
              className={psdStrong === true ? "alert-success" : "alert-danger"}
            >
              {psdStrongMsg}
            </span>
          </div>
          <div>
            <label>Confirm Password:</label>
            <input
              required
              type="password"
              name="password"
              onChange={handlePsdConfirmation}
            />
            <br />
            <span
              className={psdMatch === true ? "alert-success" : "alert-danger"}
            >
              {psdMatchMsg}
            </span>
          </div>
          <div className="checkbox-label">
            <input name="agreement" type="checkbox" required />
            <span>
              Agree to <Link to="/policy/terms">terms</Link> and{" "}
              <Link to="/policy/conditions">conditions</Link> of 5gada
            </span>
          </div>
          <div
            style={{ display: msg === "" ? "none" : "block" }}
            className={
              status === "ok" ? "alert alert-success" : "alert alert-danger"
            }
          >
            {msg}
          </div>
          {error && (
            <div className="text-center text-red-900 w-80 bg-red-700 bg-opacity-25 p-2 rounded-md">
              {error}
            </div>
          )}
          <button
            className="btn btn-secondary"
            disabled={
              !psdStrong || !psdMatch || !usernameAvailable || status === "ok"
            }
            type="submit"
          >
            SignUp
          </button>
          <Link to="/login"> have account? Login</Link>
        </form>
      </div>
    </>
  );
}

export function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${APIURL}/api/authenticate/login`,
        { username, password },
        { withCredentials: true }
      );
      dispatch(setLoginStatus(true));
      if (response.data.role === "seller") {
        navigate("/" + response.data.username);
        window.location.reload();
      } else if (response.data.role === "admin") {
        navigate("/dashboard");
        window.location.reload();
      } else {
        navigate("/buyer");
        window.location.reload();
      }
    } catch (error) {
      // console.log(error.response.data.message);
      setError(error.response.data.message);
    }
  };

  return (
    <>
      <h1 className="Header-short-line">5gada</h1>
      <div>
        <form
          method="POST"
          className="registration-form text-center"
          onSubmit={handleLogin}
        >
          <h2 style={{ textAlign: "center" }}>Login</h2>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          {error && (
            <div className="text-center text-red-900 w-80 bg-red-700 bg-opacity-25 p-2 rounded-md">
              {error}
            </div>
          )}
          <button className="btn btn-secondary" type="submit">
            Login
          </button>

          <Link to="/user/new"> Don't have account? signUp</Link>
        </form>
      </div>
    </>
  );
}
