import React from "react";
import Cart from "./cart/cart";
import Like from "./like";

export default function UserAction({product, productId }) {
  return (
    <div className="user-interaction ">
      <Like noCounter={false} productId={productId}  />
      <Cart  product={product} productId={productId} />
    </div>
  );
}

