import { Card, CardImg, CardTitle } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { APIURL } from "../../asset/host";
import UserAction from "../product-interaction/UserAction";
import { RateDispayer } from "../product-interaction/RatingsComponent";
import { LoadingCardVert, PreLoading } from "../../shared/LoadingCard";
import InfiniteScroll from "react-infinite-scroll-component";
import Loading from "../Loading";
import { TimeAgo } from "../../shared/TimeAgo";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useSelector } from "react-redux";

export function Product({ filter }) {
  const [products, setProduct] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const cards = document.querySelectorAll(".fadeInAnimation");
    cards.forEach((card, index) => {
      setTimeout(() => {
        card.classList.add("visible");
      }, 50 * index);
    });
  }, [products]);

  const fetchMoreData = () => {
    if (hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    var apiUrl = `${APIURL}/api/products?forWhom=${filter.forWhom}&sort=latest&page=${page}`;

    if (filter.forWhom) {
      apiUrl = `${APIURL}/api/products?forWhom=${filter.forWhom}&sort=latest&page=${page}`;
    } else if (filter.category) {
      apiUrl = `${APIURL}/api/products?category=${filter.category}&sort=latest&page=${page}`;
    }

    axios
      .get(apiUrl)
      .then((response) => {
        setProduct((prevProducts) => [...prevProducts, ...response.data]);
        setLoading(false);
        const newProducts = response.data;
        if (newProducts.length === 0 || newProducts.length < 10) {
          setHasMore(false);
        }
      })

      .catch((err) => {
        console.log(err);
        setLoading(false);
        setError(true);
      });
  }, [page, filter]);

  const handleProductDetail = (product) => {
    navigate(`/buyer/product/${product.id}`);
  };
  // NEED TO BE EDITED
  
  const langType = useSelector((state) => state.store.language);
  const userId = null;
  return (
    <div>
      {loading ? (
        <div className="Card_list product-list">
          <PreLoading n={6} children={<LoadingCardVert />} />
        </div>
      ) : !error ? (
        products.length > 0 ? (
          <InfiniteScroll
            dataLength={products?.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<Loading />}
          >
            <div className="Card_list product-list">
              {products.map((product, key) => (
                <Card
                  onClick={() => handleProductDetail(product)}
                  key={key}
                  className="fadeInAnimation"
                >
                  <LazyLoadImage
                    className="h-full w-full object-cover object-center"
                    alt={product.title}
                    effect="blur"
                    delayTime={3000}
                    src={`${APIURL}/api/getImage/` + product.filename}
                    height={"50%"}
                    width={"100%"}
                    placeholderSrc={
                      `${APIURL}/api/getImage/` + product.filename
                    }
                  />
                  <UserAction
                    product={product}
                    userId={userId}
                    productId={product.id}
                  />
                  <CardTitle>
                    {product.title} <br />
                    <b>{product.price} ETB</b> <br />
                    <RateDispayer productId={product.id} /> <br />
                    <span className="muted">
                      {product.num_views} views
                    </span>{" "}
                    <br />
                    <span className="muted">{TimeAgo(product.created_at,langType)}</span>
                    <br />
                    {product.condition}
                  </CardTitle>
                </Card>
              ))}
            </div>
          </InfiniteScroll>
        ) : (
          <h3 className="alert alert-warning">No Product found</h3>
        )
      ) : (
        <div className="alert alert-danger">
          Error happend while loading data
        </div>
      )}
    </div>
  );
}
