import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { CardImg } from "reactstrap";
import ProductBigger from "./showInBigger";
import { FileSizeOkay } from "./methods";
import Loading from "../Loading";
import { APIURL } from "../../asset/host";
import { useNavigate, useParams } from "react-router-dom";
import GenericModal, {
  GenericLittleLoadingModal,
} from "../../shared/GenericModal";
import { useDispatch, useSelector } from "react-redux";
import { setLoginStatus } from "../../redux/actions";
import { CreatorComponent } from "./CreatorComponent";
import { ProductCategory } from "./ProductCategory";
import { Tooltip } from "react-tooltip";
import { GrCircleQuestion } from "react-icons/gr";
import PlaceSelector from "./placeSelector";
function HaveCompany() {
  const [haveCompany, setHaveCompany] = useState(false);
  const [Loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .get(`${APIURL}/api/seller/getMyCompany`, {
        withCredentials: true,
      })
      .then((res) => {
        setHaveCompany(res.data);
        setLoading(false);
      })
      .catch((err) => {
        if (
          err.response.status === 401 ||
          err.response.status === 403 ||
          err.response.status === 402
        ) {
          setLoading(false);
          setLoggedIn(false);
          dispatch(setLoginStatus(false));
          // navigate("/login");
        }
      });
  }, []);

  const [ModalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    if (!haveCompany && !Loading) {
      setModalOpen(!haveCompany);
    }
  }, [haveCompany, Loading]);

  const closeModal = () => {
    setModalOpen(false);
  };

  const username = useSelector((state) => state.store.username);
  useEffect(() => {
    if (!Loading && (!haveCompany || !loggedIn) && ModalOpen) {
      const timeoutId = setTimeout(() => {
        navigate(`/${username}/company`);
      }, 2000);

      return () => clearTimeout(timeoutId); // Clear the timeout on component unmount
    }
  }, [Loading, haveCompany, loggedIn, ModalOpen, navigate, username]);

  return (
    <>
      {!Loading && (!haveCompany || !loggedIn) ? (
        <GenericModal isOpen={ModalOpen} onClose={closeModal}>
          <p>You need to have a company, please create a new company first</p>
        </GenericModal>
      ) : (
        <></>
      )}
    </>
  );
}

export default function ProductForm() {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(
    Array(selectedFiles.length).fill(false)
  );
  const [hasImage, setHasImage] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msg, setMsg] = useState("");

  const { username } = useParams();
  const [productData, setProductData] = useState({
    title: "",
    productDescription: "",
    productPrice: "",
    additionalInfo: false,
    specialOffer: "",
    discount: "",
    tags: "",
    warranty: "",
  });

  const uploadable = selectedFiles
    .filter((file) => FileSizeOkay(file))
    .slice(0, 5);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? checked : value;

    if (!(type === "file")) {
      // setSelectedFiles([...event.target.files]);
      setProductData((prevData) => ({
        ...prevData,
        [name]: newValue,
      }));
    }
  };

  function handleFileChange(event) {
    const files = event.target.files;
    setSelectedFiles((prevSelectedFiles) => {
      const uniqueFiles = Array.from(files).filter(
        (file) =>
          !prevSelectedFiles.some(
            (selectedFile) => selectedFile.name === file.name
          )
      );
      return [...prevSelectedFiles, ...uniqueFiles];
    });
  }

  const handleFileUpload = async () => {
    if (selectedFiles.length === 0) {
      setHasImage(false);
      return;
    }
    try {
      uploadable.forEach((file, index) => {
        const formData = new FormData();
        formData.append("productImages", file);

        setIsLoading((prevLoadingState) => {
          const newLoadingState = [...prevLoadingState];
          newLoadingState[index] = true;
          return newLoadingState;
        });

        axios
          .post(`${APIURL}/api/uploads/file`, formData, {
            withCredentials: true,
          })
          .then((response) => {
            if (response.status === 200) {
              setHasImage(true);
              setIsLoading((prevLoadingState) => {
                const newLoadingState = [...prevLoadingState];
                newLoadingState[index] = false;
                return newLoadingState;
              });
            }
          })
          .catch((err) => {
            console.log(err);
            setHasImage(false);
          });
      });
    } catch (error) {
      setHasImage(false);
      console.error("Error uploading files:", error);
    }
  };

  useEffect(() => {
    if (selectedFiles.length > 0) {
      handleFileUpload();
    }
  }, [selectedFiles]);

  const handleSubmit = (event) => {
    setLoading(true);
    event.preventDefault();
    console.log(productData);
    axios
      .post(`${APIURL}/api/uploads`, productData, {
        withCredentials: true,
      })
      .then((response) => {
        setLoading(false);

        setMsg(response.data.message);
        setTimeout(() => navigate(`/${username}`), 3000);
        setSuccess(true);
      })
      .catch((error) => {
        setLoading(false);
        setMsg(error.response.data.message);
      });
  };

  const [showDetail, setShowDetail] = useState(false);
  const [showVal, setShowVal] = useState([]);
  const [editable, setEditable] = useState([]);
  const productBiggerRef = useRef(null);

  function handleProductClick(val, index) {
    setShowDetail(true);
    setShowVal(val);
    setEditable(index);
  }

  function replaceProduct(file) {
    setSelectedFiles((prevSelectedFiles) => {
      const newSelectedFiles = [...prevSelectedFiles];
      newSelectedFiles[editable] = file;
      return newSelectedFiles;
    });
  }

  useEffect(() => {
    if (showDetail) {
      productBiggerRef.current.openModal();
      //console.log(editable);
    }
  }, [showVal, showDetail]);
  const [openLoading, setOpenLoading] = useState(false);
  useEffect(() => {
    setOpenLoading(loading);
  }, [loading]);
  return (
    <>
      <GenericLittleLoadingModal isOpen={openLoading} />
      <HaveCompany />
      {showDetail && (
        <GenericModal onClose={() => setShowDetail(false)} isOpen={showDetail}>
          <ProductBigger
            replaceProduct={replaceProduct}
            ref={productBiggerRef}
            title={"image"}
            body={showVal}
          />
        </GenericModal>
      )}
      <>
        {msg ? (
          <div className="card-non-hover">
            <div
              className={success ? "alert alert-success" : "alert alert-danger"}
            >
              {msg}
            </div>
          </div>
        ) : (
          <div className="registration-form posting-page">
            <form>
              <h4>Product Image/video</h4>
              <hr />
              {uploadable.length > 0 && (
                <div
                  style={{
                    height: "60px", // To create a perfect circle
                    overflow: "hidden",
                    marginBottom: "10px", // To clip the circular progress inside the circle
                    display: "flex",
                    flexWrap: `wrap`,
                  }}
                >
                  {uploadable.map((file, index) => (
                    <div
                      key={index}
                      style={{
                        position: "relative",
                      }}
                    >
                      {isLoading[index] && (
                        <div
                          style={{
                            position: "absolute",
                            left: `20%`,
                            top: `15%`,
                          }}
                        >
                          <Loading />
                        </div>
                      )}

                      {file.type.startsWith("image/") ? ( // Check if it's an image
                        <CardImg
                          src={URL.createObjectURL(file)}
                          onClick={() =>
                            handleProductClick(URL.createObjectURL(file), index)
                          }
                          alt="Selected Image"
                          name={file.name}
                          style={{
                            width: "50px",
                            height: "50px",
                            objectFit: "cover",
                            opacity: isLoading[index] ? 0.5 : 1,
                          }}
                        />
                      ) : file.type.startsWith("video/") ? ( // Check if it's a video
                        <video
                          src={URL.createObjectURL(file)}
                          alt="Selected Video"
                          onClick={() =>
                            handleProductClick(URL.createObjectURL(file), index)
                          }
                          autoPlay
                          muted
                          style={{
                            width: "50px",
                            height: "50px",
                            objectFit: "cover",
                            opacity: isLoading[index] ? 0.5 : 1,
                          }}
                        />
                      ) : null}
                    </div>
                  ))}
                </div>
              )}

              <div>
                {selectedFiles.length < 5 && (
                  <label htmlFor="productFile">
                    <i className="fa-solid fa-plus 2x"></i>{" "}
                    <i className="fa-solid fa-photo-film fa-2x"></i> Add product
                    Images
                  </label>
                )}
                <input
                  type="file"
                  id="productFile"
                  name="productImages"
                  onChange={handleFileChange}
                  required
                  style={{ display: "none" }}
                  multiple
                  accept="image/*" // Optional: Restrict file types to images only (for example)
                  max="5" // Restrict the number of files to 5
                />
              </div>
            </form>
            <form
              method="post"
              encType="multipart/form-data"
              onSubmit={handleSubmit}
            >
              <h4>General information about product</h4>
              <hr />
              <div>
                <div>
                  <label htmlFor="productName">Product Title:</label>
                  <input
                    type="text"
                    id="productName"
                    name="title"
                    value={productData.title}
                    onChange={handleInputChange}
                    required
                    placeholder="Enter product title..."
                  />
                </div>
                <div>
                  <label htmlFor="productDescription">
                    Product Description:
                  </label>
                  <textarea
                    id="productDescription"
                    name="productDescription"
                    value={productData.productDescription}
                    onChange={handleInputChange}
                    placeholder="Enter product description..."
                    rows="3"
                    required
                  />
                </div>

                <div>
                  <label htmlFor="productPrice">Product Price:</label>
                  <input
                    type="number"
                    id="productPrice"
                    name="productPrice"
                    value={productData.productPrice}
                    onChange={handleInputChange}
                    step="0.01"
                    placeholder="Enter single product price"
                    required
                  />
                </div>
              </div>
              <h4>Categories</h4>
              <hr />
              <div>
                <div>
                  <label>
                    Category
                    <ProductCategory
                      handleCategorySelect={(Categories) => {
                        setProductData({
                          ...productData,
                          Categories: Categories.toString(),
                        });
                      }}
                    />
                  </label>
                </div>
                <div>
                  <label>Made For</label>
                  <select onChange={handleInputChange} required name="forWhom">
                    <option value="">Made For</option>
                    <option value="kid">kid</option>
                    <option value="men">men</option>
                    <option value="women">women</option>
                    <option value="general">general</option>
                    <option value="all">for all</option>
                  </select>
                </div>
                <div>
                  <label>condition</label>
                  <select
                    onChange={handleInputChange}
                    required
                    name="condition"
                  >
                    <option value="">is it new or used</option>
                    <option value="new">new</option>
                    <option value="used">used</option>
                  </select>
                </div>
              </div>
              <h4>Location and Delivery information</h4>
              <hr />
              <div>
                <div>
                  <label>Delivery</label>
                  <select name="delivery" onChange={handleInputChange} required>
                    <option value="">Select delivery option</option>
                    <option value="available">available</option>
                    <option value="none">none</option>
                  </select>
                </div>
                <div>
                  <label>Location</label>
                  <PlaceSelector
                    handlePlaceSelect={(place) => {
                      // console.log(place);F
                      setProductData({
                        ...productData,
                        Location: place.toString(),
                      });
                    }}
                  />
                </div>
              </div>
              {!productData.additionalInfo && (
                <div className="checkbox-label">
                  <input
                    type="checkbox"
                    id="additionalInfo"
                    name="additionalInfo"
                    checked={productData.additionalInfo}
                    onChange={handleInputChange}
                    style={{ display: "none" }}
                  />{" "}
                  <label htmlFor="additionalInfo">
                    <span className="btn btn-primary">
                      <i className="fa-solid fa-plus"></i> Additional
                      Information
                    </span>
                  </label>
                </div>
              )}
              {productData.additionalInfo && (
                <div className="checkbox-label">
                  <input
                    type="checkbox"
                    id="additionalInfo"
                    name="additionalInfo"
                    checked={productData.additionalInfo}
                    onChange={handleInputChange}
                    style={{ display: "none" }}
                  />{" "}
                  <label htmlFor="additionalInfo">
                    <span className="btn btn-primary">
                      <i className="fa-solid fa-minus"></i> Additional
                      Information
                    </span>
                  </label>
                </div>
              )}
              {productData.additionalInfo && (
                <>
                  <h4>More information about your Product</h4>
                  <hr />

                  <div id="additionalForm">
                    <div>
                      <label htmlFor="specialOffer">Special Offer:</label>
                      <input
                        type="text"
                        name="specialOffer"
                        value={productData.specialOffer}
                        placeholder="Enter special offer"
                        onChange={handleInputChange}
                      />
                    </div>
                    <div>
                      <label htmlFor="discount">Discount:</label>
                      <input
                        type="text"
                        id="discount"
                        name="discount"
                        value={productData.discount}
                        placeholder="Enter discount in percent like 30% .."
                        onChange={handleInputChange}
                      />
                    </div>
                    <div>
                      <label htmlFor="warranty">warranty info:</label>
                      <input
                        type="text"
                        id="warranty"
                        name="warranty"
                        value={productData.warranty}
                        placeholder="Enter warranty information"
                        onChange={handleInputChange}
                      />
                    </div>
                    {/* Add more additional attributes as needed */}
                  </div>
                </>
              )}
              <div className="relative">
                <h4>Searchable Tag </h4>
                <GrCircleQuestion
                  data-tooltip-id="Searchable_Tag"
                  data-tooltip-content="creating tag help you to optimize your product to appear in search"
                  className="absolute -top-1 left-24 ml-2 cursor-pointer"
                />
                <Tooltip id="Searchable_Tag" />
              </div>
              <hr />
              <div>
                <label>Add Tags:</label>
                <CreatorComponent
                  handleTagCreation={(tag) => {
                    console.log(tag);
                    setProductData({
                      ...productData,
                      tags: tag.toString().trim(),
                    });
                  }}
                />
              </div>
              <button
                disabled={!hasImage}
                title="pls give all required input"
                className="btn btn-secondary"
                type="submit"
              >
                Post
              </button>{" "}
              {!hasImage && (
                <div className="alert alert-danger">
                  Need to have at least one Picture of your products
                </div>
              )}
            </form>
          </div>
        )}
      </>
    </>
  );
}
