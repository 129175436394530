import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { APIURL } from "../asset/host";
import CartDisplayer from "./product-interaction/cart/cartDisp";
import GenericModal from "../shared/GenericModal";
import { Login } from "./Login_registration";
import { useDispatch, useSelector } from "react-redux";
import { setCarts } from "../redux/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { GrGallery } from "react-icons/gr";
import Breadcrumbs from "./Breadcrumbs";
import { motion } from "framer-motion";
import { ServiceLang, lang, path, pathLang } from "../asset/language";
import LanguageSelector from "./LanguageSelector";

export function BuyerNav({ updateCart }) {
  // const [cartsNum, setCartsNum] = useState(null);
  const [cartDisp, setCartDisp] = useState(false);
  const [cartNumChanged, setCartNumChanged] = useState(false);
  const dispatch = useDispatch();
  const cartsNum = useSelector((state) => state.store.cartsNum);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      isMobile
        ? setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 80)
        : setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 45);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  const handleCartClick = () => {
    if (!isLoggedIn) {
      setLoginIsModalOpen(true);
    } else setCartDisp(!cartDisp);
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (isLoggedIn) {
      axios
        .get(`${APIURL}/api/products/user/carts`, {
          withCredentials: true,
        })
        .then((res) => {
          setCartNumChanged(false);
          dispatch(setCarts(res.data.Carts.nums));
        })
        .catch((err) => {
          if (err.response) {
            // The request was made, but the server responded with an error status
            console.error(
              "Server responded with an error:",
              err.response.status,
              err.response.data
            );
          } else if (err.request) {
            // The request was made, but no response was received
            console.error("No response received:", err.request);
          } else {
            // Something happened in setting up the request
            console.error("Error setting up the request:", err.message);
          }
        });
    }
  }, [cartNumChanged, updateCart]);

  const isLoggedIn = useSelector((state) => state.store.isLogin);
  const username = useSelector((state) => state.store.username);

  function handleFavoritesClick() {
    if (!isLoggedIn) {
      setLoginIsModalOpen(true);
    } else {
      navigate(`/${username}/my-favorites`);
    }
  }

  const [isLoginModalOpen, setLoginIsModalOpen] = useState(false);

  const closeLoginModal = () => {
    setLoginIsModalOpen(false);
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get("q");
  const [search, setSearch] = useState(query);

  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    searchParams.set("q", search);
    const updatedSearchParams = searchParams.toString();
    const newURL = `/search/?${updatedSearchParams}`;
    navigate(newURL);
    window.location.reload();
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const langType = useSelector((state) => state.store.language);

  return (
    <>
      {cartDisp && (
        <CartDisplayer
          cartChanged={() => setCartNumChanged(true)}
          closeCartDisp={() => setCartDisp(false)}
        />
      )}
      <motion.div
        initial={{ y: 0, scale: 1 }}
        animate={{ y: visible ? 0 : "-100%" }}
        exit={{ scale: 1, y: 0 }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { y: 0, transition: { duration: 0.3, ease: "easeOut" } },

          hidden: {
            y: "-100%",
            transition: { duration: 0.3, ease: "easeOut" },
          },
        }}
        className={` z-10  fixed top-0 `}
      >
        <div
          className={`navBar-header mobile ${
            prevScrollPos > 0 && visible && "shadow"
          }`}
        >
          <div className="logo">
            <Link to="/">
              {isMobile ? (
                <img alt="logo" loading="eager" src="/images/minilogo.png" />
              ) : (
                <img alt="logo" loading="eager" src="/images/logo15.png" />
              )}
            </Link>
          </div>

          <div className="nav-menu">
            <nav className="right-side">
              <ul>
                <li>
                  <Link className="link mobile_hidden" to="/buyer">
                    <i className="fa-solid fa-house"></i>
                    <span className="mobile_hidden">
                      {ServiceLang[langType].home}
                    </span>
                  </Link>
                </li>
                <li>
                  <form onSubmit={handleSubmit} className="search-form">
                    <div className="search-container">
                      <input
                        type="text"
                        name="search"
                        className="search-input"
                        placeholder={lang[langType].searchPlaceholder}
                        value={search}
                        onChange={handleChange}
                      />

                      <button type="submit" className="search-button">
                        <i className="fas fa-search"></i>
                        <span className="mobile_hidden">
                      
                          {lang[langType].search}
                        </span>
                      </button>
                    </div>
                  </form>
                </li>
                <li className="">
                  <Link
                    className="link flex mobile_hidden items-center"
                    to="/gallery"
                  >
                    <GrGallery className="" />
                    {ServiceLang[langType].gallery}
                  </Link>
                </li>
              </ul>
            </nav>
            <div className=" top-0 absolute text-sm right-16 sm:static">
              <LanguageSelector />
            </div>
            <div className="Login-signUp mobile_hidden right-side-nav">
              <div style={{ position: "relative" }} onClick={handleCartClick}>
                {cartsNum > 0 && (
                  <span
                    style={{ top: "-20%", right: "-34%" }}
                    className="notification-num"
                  >
                    {cartsNum}
                  </span>
                )}
                <i className="fa fa-shopping-cart"></i>
                <div>{ServiceLang[langType].cart} </div>
              </div>
              <div onClick={handleFavoritesClick}>
                <div className="link">
                  <FontAwesomeIcon icon={faHeart} className="mr-1 text-md" />
                  <div> {ServiceLang[langType].favorite}</div>
                </div>
              </div>
              <span className="mobile_hidden  right-side-nav-user">
                {isLoggedIn ? (
                  <div
                    className="user-pr"
                    onClick={() => {
                      navigate(`/${username}`);
                    }}
                  >
                    <img src="/images/user-icon-placeholder-1.png" />
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      window.location.href = "/login";
                    }}
                    className="btn btn-secondary "
                  >
                    <i className="fa fa-user"></i>
                    <span className="mobile_hidden">Login</span>
                  </div>
                )}
              </span>
            </div>
          </div>
        </div>
      </motion.div>

      <div className="mobile-nav">
        <nav className="right-side">
          <ul>
            <li>
              <Link className="link" to="/buyer">
                <i className="fa-solid fa-house"></i>
              </Link>
            </li>
            <li>
              <Link className="link" to="/gallery">
                <GrGallery />
              </Link>
            </li>

            <li style={{ position: "relative" }} onClick={handleCartClick}>
              {cartsNum > 0 && (
                <span className="notification-num">{cartsNum}</span>
              )}
              <i className="fa fa-shopping-cart"></i>
            </li>
            <li onClick={handleFavoritesClick}>
              <span className="link">
                <FontAwesomeIcon icon={faHeart} className="mr-1 text-md" />
              </span>
            </li>

            <li className="user-login-action">
              {isLoggedIn ? (
                <div
                  className="user-pr"
                  onClick={() => {
                    navigate(`/${username}`);
                  }}
                >
                  <img src="/images/user-icon-placeholder-1.png" />
                </div>
              ) : (
                <div
                  onClick={() => {
                    window.location.href = "/login";
                  }}
                  className="btn btn-secondary"
                >
                  <i className="fa fa-user"></i>
                </div>
              )}
            </li>
          </ul>
        </nav>
      </div>

      <div className="mt-24 md:mt-12">
        {" "}
        <div className="navBar-header Category-bar">
          <nav>
            <ul>
              <li>
                <Link className="link" to="/buyer">
                  {ServiceLang[langType].featured}
                </Link>
              </li>
              <li>
                <Link className="link" to="/buyer/new">
                  {pathLang[langType].new}
                </Link>
              </li>
              <li>
                <Link className="link" to="/buyer/kids">
                  {pathLang[langType].kids}
                </Link>
              </li>
              <li>
                <Link className="link" to="/buyer/Men's">
                  {ServiceLang[langType].mens}
                </Link>
              </li>
              <li>
                <Link className="link" to="/buyer/Women's">
                  {ServiceLang[langType].womens}
                </Link>
              </li>
              <li>
                <Link className="link" to="/buyer/Meeshale-aadaa">
                  Meeshale-aadaa
                </Link>
              </li>
              <li>
                <Link className="link" to="/buyer/Jewelries">
                  {ServiceLang[langType].Jewelries}
                </Link>
              </li>
              <li>
                <Link className="link" to="/buyer/couples">
                  {ServiceLang[langType].couple}
                </Link>
              </li>
              <li>
                <Link className="link" to="/buyer/family">
                  {ServiceLang[langType].family}
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <>
        {!isLoggedIn && isLoginModalOpen ? (
          <GenericModal isOpen={isLoginModalOpen} onClose={closeLoginModal}>
            <h4>You need to login to access your orders</h4>
            <Login />
          </GenericModal>
        ) : (
          <></>
        )}
      </>
      <Breadcrumbs />
    </>
  );
}
