import { faPhoneSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { FaFacebook, FaTelegram } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function UserContact({ userInfo }) {
  return (
    <div className="flex gap-3 m-3">
      <div>
        <Link to={userInfo.telegram} className="link-primary flex items-center">
          <FaTelegram className="mr-0.5 text-xl text-blue-400" />
          telegram
        </Link>
      </div>
      <div>
        <Link
          to={`tel:${userInfo.phoneNumber}`}
          className="link-primary flex items-center"
        >
          <FontAwesomeIcon
            icon={faPhoneSquare}
            className="mr-0.5 text-xl text-red-600"
          />
          Call
        </Link>
      </div>
      <div>
        <Link className="link-primary flex items-center">
          <FaFacebook className="mr-0.5 text-xl text-blue-700" />
          Facebook
        </Link>
      </div>
    </div>
  );
}
