import React, { useEffect, useState } from "react";
import axios from "axios";
import Rating from "./rating"; // Make sure to use the correct path
import { APIURL } from "../../asset/host";
import GenericModal from "../../shared/GenericModal";
import { Login } from "../Login_registration";
import { actionLang } from "../../asset/language";
import { useSelector } from "react-redux";

const StarRating = ({ averageRating }) => {
  const totalStars = 5;
  const fullStars = Math.floor(averageRating);
  const halfStar = averageRating - fullStars >= 0.5;

  const starIcons = [];

  for (let i = 0; i < fullStars; i++) {
    starIcons.push(<i key={i} className="fas fa-star"></i>);
  }

  if (halfStar) {
    starIcons.push(<i key="half" className="fas fa-star-half"></i>);
  }

  const remainingStars = totalStars - starIcons.length;
  for (let i = 0; i < remainingStars; i++) {
    starIcons.push(<i key={`empty${i}`} className="far fa-star"></i>);
  }

  return <>{starIcons}</>;
};

export const ProductRating = ({ productId }) => {
  const [productRating, setProductRating] = useState(0);

  const [isLoggedIn, setLoggedIn] = useState(true);

  const handleRatingChange = async (newRating) => {
    try {
      setProductRating(newRating);
      await axios.post(
        `${APIURL}/api/product/rate`,
        {
          productId,
          rating: newRating,
        },
        {
          withCredentials: true,
        }
      );
    } catch (error) {
      // console.error("Error rating the product:", error);
      if (error.response.status === 401) {
        setLoggedIn(false);
      } else {
      }
    }
  };
  const [isLoginModalOpen, setLoginIsModalOpen] = useState(false);

  useEffect(() => {
    setLoginIsModalOpen(!isLoggedIn);
  }, [isLoggedIn]);
  const closeLoginModal = () => {
    setLoginIsModalOpen(false);
    setLoggedIn(true);
  };

  const langType = useSelector((state) => state.store.language);
  return (
    <div>
      <RateDispayer productId={productId} />
      <h3>{actionLang[langType].rateprod}</h3>
      <Rating initialValue={productRating} onChange={handleRatingChange} />
      {!isLoggedIn && (
        <>
          <GenericModal
            isOpen={isLoginModalOpen}
            onClose={closeLoginModal}
            children={<Login />}
          />
        </>
      )}
    </div>
  );
};

export function RateDispayer({ productId }) {
  const [productTotRate, setProductTotRate] = useState({
    avgRating: 0,
    numRatings: 0,
  });
  useEffect(() => {
    const fetchProductRatingCount = async () => {
      try {
        const response = await axios.get(
          `${APIURL}/api/product/ratings/count/${productId}`
        );
        setProductTotRate(response.data);
      } catch (error) {
        console.error("Error fetching product ratings:", error);
      }
    };

    fetchProductRatingCount();
  }, [productId]);

  return (
    <span className="muted">
      <StarRating averageRating={productTotRate.avgRating} />{" "}
      {productTotRate.avgRating} | {productTotRate.numRatings} reviews
    </span>
  );
}

export const CompanyRating = ({ companyId, user_id }) => {
  const [companyRating, setCompanyRating] = useState([]);
  const handleRatingChange = (newRating) => {
    setCompanyRating(newRating);
    axios
      .post(`${APIURL}/api/company/rate/${user_id}`, {
        companyId,
        rating: newRating,
      })
      .catch((err) => {
        if (err.response) {
          // The request was made, but the server responded with an error status
          console.error(
            "Server responded with an error:",
            err.response.status,
            err.response.data
          );
        } else if (err.request) {
          // The request was made, but no response was received
          console.error("No response received:", err.request);
        } else {
          // Something happened in setting up the request
          console.error("Error setting up the request:", err.message);
        }
      });
  };
  async function getCompanyRatings() {
    try {
      const response = await axios.get(`${APIURL}/company/ratings`);
      console.log(response.data); //  return ;
    } catch (error) {
      console.error("Error fetching company ratings:", error);
      throw error;
    }
  }
  return (
    <div>
      <h3>Rate the Company:</h3>
      <Rating initialValue={companyRating} onChange={handleRatingChange} />
    </div>
  );
};
