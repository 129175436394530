import React, { useState } from 'react';
import './Rating.css';

const Rating = ({ initialValue, onChange }) => {
  const [rating, setRating] = useState(initialValue);
  const [hoverRating, setHoverRating] = useState(null);

  const handleRatingChange = (newRating) => {
    setRating(newRating);
    onChange(newRating);
  };

  return (
    <div className="rating">
      {[1, 2, 3, 4, 5].map((value) => (
        <span
          key={value}
          className={`star ${value <= (hoverRating || rating) ? 'filled' : ''}`}
          onMouseEnter={() => setHoverRating(value)}
          onMouseLeave={() => setHoverRating(null)}
          onClick={() => handleRatingChange(value)}
        >
          <i className="fas fa-star"></i>
        </span>
      ))}
    </div>
  );
};

export default Rating;
