import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import "./MyImageModal.css";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useSwipeable } from "react-swipeable";
import { motion } from "framer-motion";

function MyImageModal({ src, alt, closeModal, next, prev, length, index }) {
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      index + 1 !== length && next();
    },
    onSwipedRight: () => {
      index !== 0 && prev();
    },
  });
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (index !== 0 && e.key === "ArrowLeft") {
        prev();
      } else if (index + 1 !== length && e.key === "ArrowRight") {
        next();
      } else if (e.key === "Escape") {
        closeModal();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [closeModal, next, prev]);

  const [isVisible, setIsVisible] = useState(true);

  return (
    <div 
      onClick={closeModal}
      className="fixed modal top-0 left-0 h-full w-full z-50 bg-black bg-opacity-50 flex items-center justify-center"
    >
      <div onClick={(e) => e.stopPropagation()}>
        <span
          onClick={closeModal}
          className="text-red-500 hover:text-red-700 rounded-lg p-1 hover:bg-gray-700 hover:bg-opacity-80  pr-0 flex justify-center z-20 font-bold text-2xl cursor-pointer absolute top-2 right-5"
        >
          <FontAwesomeIcon icon={faClose} className="mr-1 text-md" />
        </span>
        <div className="absolute text-white left-1 top-0 p-2  bg-black opacity-55">
          from {alt}
        </div>
        {index !== 0 && (
          <div
            onClick={prev}
            className="text-gray-300 z-10 font-extrabold text-3xl md:text-5xl cursor-pointer absolute top-0 left-0 h-full flex items-center p-0 m-0 hover:bg-black hover:bg-opacity-35 hover:text-white"
          >
            <IoIosArrowBack />
          </div>
        )}
        {index + 1 !== length && (
          <div
            onClick={next}
            className="text-gray-300 z-10 font-extrabold text-3xl md:text-5xl cursor-pointer absolute top-0 right-0 h-full flex items-center p-0 m-0 hover:bg-black hover:bg-opacity-35 hover:text-white"
          >
            <IoIosArrowForward />
          </div>
        )}
        <motion.div
          initial={{ scale: 0.5, opacity: 0 }}
          animate={{ scale: isVisible ? 1 : 0.5, opacity: isVisible ? 1 : 0 }}
          exit={{ scale: 0.5, opacity: 0 }}
          transition={{ duration: 0.07 }}
          {...handlers}
          className="modal-content p-0 bg-black rounded-lg overflow-hidden"
        >
          <Zoom>
            <img
              alt="That Wanaka Tree, New Zealand by Laura Smetsers"
              src={src}
              className="w-full h-auto md:h-full"
            />
          </Zoom>
        </motion.div>
      </div>
    </div>
  );
}

export default MyImageModal;
