export const lang = {
  en: {
    Contact_Supplier: "Contact Supplier",
    second: "second",
    minute: "minute",
    month: "month",
    year: "year",
    day: "day",
    hour: "hour",
    just_now: "Just now",
    about: "About Us",

    welcome: "welcome",
    welcome25gada: "Welcome to 5gada",
    homebannerh1: "5gada: Beyond Commerce, A Celebration of Oromo Identity.",
    homeMessagep:
      "Authentic Oromo culture online. Verified sellers, personalized experience. Preserve heritage with every click",
    explore_more: "Explore more",
    search: "Search",
    searchPlaceholder: "Search for product",
    be_seller: "Become A Seller",
    be_buyer: "Become A Buyer",
    get_app: "Get Our App",
    top_prodh1: "Top Products Categories",
    see_more: "see more",
    abt_title: "5gada - Celebrating Oromo Culture",
    abt_bannerp: "Explore a world of Oromo cultural products and services.",
    abt5g: "About 5gada",
    abt5gp:
      "Welcome to 5gada, a platform dedicated to celebrating Oromo culture through tailor and designer services. Our mission is to connect Oromo tailors, designers, and enthusiasts, providing a space to showcase and explore the rich cultural products and services within the community.",
    discover_service: "Discover Oromo Tailor and Designer Services",
    disc_msg:
      "Explore a vibrant collection of Oromo designer products and services. Whether you're a buyer looking for unique designs or a  designer ready to showcase your business, 5gada is the platform for you.",
    exp_pro: "Explore Products",
    prom_u_serv: "Promote Your Services",
    discover_fashn: "Discover Oromo's Design and Fashion Gallery",
    gal_msg:
      " Explore a curated collection of Oromo's design and fashion in one place. Immerse yourself in the beauty of traditional and contemporary Oromo products, all conveniently gathered in this gallery.",
    exp_gall: "Explore Gallery",
    k_feat: "Key Features",
    feat1h: " Product and Services Promotion",
    feat2h: " Design and Fashion Gallery",
    feat3h: "Carting and Saving Products and Services",
    feat4h: "Products and Seller Company Review",
    feat5h: "Product and Design View Counting",
    feat6h: "Fashion and Design Events",
    feat1p:
      "Showcase and promote your products and services to reach a wider audience.",
    feat2p:
      "Explore a curated gallery of designs and fashion trends from the Oromo community.",
    feat3p:
      "Easily add products and services to your cart and save them for future reference.",
    feat4p:
      "Leave reviews and ratings for products and seller companies to share your feedback.",
    feat5p:
      "Track the popularity of your products and designs through view counting.",
    feat6p:
      " Stay updated on upcoming fashion and design events within the Oromo community.",
    price: "Price",
    owner: "owner",
    JoinedDate: "Joined Date",
    addToCart: "Add to cart",
    footerDesc:
      "Welcome to the 5Gada online marketing platform, where you have the opportunity to freely buy, sell and explore a wide range of goods and services.😄",
  },
  om: {
    footerDesc:
      "Baga gara waltajjii gabaa onlaayinii 5Gada, bakka meeshaalee fi tajaajiloota adda addaa bilisaan bitachuu, gurguruu, Beeksisuu fi qorachuuf carraa qabdanitti dhuftan.😄",
    addToCart: "Gaariti Dabalii",
    Contact_Supplier: "Gurguraa dubbisi",
    about: "Waa'ee Keenya",
    JoinedDate: "Guyyaa Gale",
    owner: "abbaa",
    price: "Gatii",
    just_now: "reefuma",
    year: "waggaa",
    month: "ji'a",
    hour: "sa'aatii",

    day: "guyyaa",
    second: "sekondii",
    minute: "daqiiqaa",
    welcome: "ana haa dhufu",
    welcome25gada: "Baga gara 5gada dhuftan",
    homebannerh1: "5gada: Daldala bira darbee, Kabaja Eenyummaa Oromoo.",
    homeMessagep:
      "Aadaa Oromoo dhugaa saybeerin. Gurgurtoota mirkanaa'an, muuxannoo dhuunfaa. Cuqaasuu hundaan hambaa kunuunsitu",
    explore_more: "dabalataan qoradhu",
    search: "Barbaadi",
    searchPlaceholder: "Oomishaalee barbaadi",
    be_buyer: "Maamila Ta'i",
    be_seller: "Daldalaa ta'i",
    top_prodh1: "Tarree Omishaalee Filatamoo",
    get_app: "App Keenya Buufadhu",
    see_more: "dabalataan laali",
    abt_title: "5gada - Kabaja aadaa oromoo",
    abt_bannerp: "Addunyaa oomishaalee fi tajaajiloota aadaa Oromoo qoradhu.",
    abt5g: "Waa'ee 5gada",
    abt5gp:
      "Waltajjii aadaa Oromoo, karaa tajaajila  hodhitoota/tolchitoota fi dizaayineeroota beeksisuu fi argisiisuuf of kenne 5gadati baga nagaan dhuftan. Ergamni keenya hodhitoota, dizaayinaroota, fi quuqamtoota Oromoo walitti qabuu, bakka oomishaalee aadaa fi tajaajiloota aadaa badhaadhoo hawaasa keessa jiran itti agarsiifamu fi qoratamu Uumudha.",
    discover_service: "Tajaajila Uffata Hodhitoota Fi Dizaayineeroota Argadhaa",
    disc_msg:
      "Kuusaa oomishaalee fi tajaajiloota dizaayinara Oromoo hedduu ta'an qoradhu. maamila dizaayinoota adda ta'an barbaadu, yookiin dizaayinara daldala kee agarsiisuuf qophaaftes yoo taate, 5gada'n waltajjii siif ta'u.",
    exp_pro: "Oomishaalee Laali",
    prom_u_serv: "Tajaajila kee Beeksisi",
    discover_fashn: "kuufama Dizaayinii fi Faashinii Oromoo argadhu",
    gal_msg:
      "Kuusaa dizaayinii fi faashinii Oromoo bakka tokkotti ilaali. Miidhagina oomishaalee Oromoo aadaa fi ammayyaa, hunduu haala mijataan galma kana keessatti walitti qabaman yaadan itti lixi.",
    exp_gall: "kuusaa suuraa ilaali",
    k_feat: "Tajaajiloota Gurgoddoo",
    feat1h: "Beeksisa Omishaa fi Tajaajila",
    feat2h: "kuusaa Dizaayinii fi Faashinii",
    feat3h: "Oomishaalee fi Tajaajiloota kuusachu fi olkaa'achuu",
    feat4h: "Gamaaggama Oomishaalee fi Dhaabbata Daldalaa",
    feat5h: "Lakkoofsa haga ilaalama Omishaa fi Dizaayinii",
    feat6h: "Taateewwan Faashinii fi Dizaayinii",
    feat2p:
      "kuufama suuraalee diizaayinii fi faashinii haarawaa fi filatamoo hawaasa Oromoo kan walitti qabateedha",
    feat3p:
      "Oomishaa fi tajaajiloota salphaatti gara olkaawwi keessanitti dabaluudhaan gara fuulduraatti akka ilaaltaniif ka'attu.",
    feat1p:
      "Oomishaalee fi tajaajiloota kee agarsiisuu fi beeksisuun hawaasaa bal'aa biraan ga'uu.",
    feat6p:
      "Taateewwan faashinii fi dizaayinii hawaasa Oromoo keessatti dhufan irratti odeeffannoo haaraa argattu.",
    feat4p:
      "Yaada keessan qooduuf, gamaaggamaa fi sadarkaa oomishaalee fi dhaabbilee gurgurtootaaf keessu.",
    feat5p:
      "Jaalatamummaa oomishaale fi dizaayinoota keessanii karaa lakkoofsa haga ilalameen hordoftu.",
  },
};
export const ServiceLang = {
  om: {
    arsii: "uffata aadaa oromo arsi",
    baale: "uffata aadaa oromo baale",
    wallaga: "uffata aadaa oromoo wallaggaa",
    wallo: "uffata aadaa oromoo walloo",
    shawaa: "uffata aadaa oromoo wallaggaa",
    "5gmen": "Oomishaalee aadaa dhiiraaf",
    "5gwomen": "Oomishaalee aadaa dubartootaf",
    "5gkid": "Oomishaalee aadaa Ijjoollef",
    mens: "Dhiiraf",
    womens: "Dubartiif",
    couple: "Misirootaf",
    couple1: "Jalalleewaniif",
    wedding: "Ciidhaaf",
    family: "Maatif",
    featured: "Jaalatamoo",
    gallery: "Suuralee",
    home: "Ka'umsa",
    cart: "Gaarii",
    Jewelries: "Faayaalee aadaa",

    favorite: "Filannaako",
    contact_Us: "Contact Us",
    pages: "Pages",
  },
  en: {
    pages: "Fuulota",
    contact_Us: "Contact Us",
    cart: "Cart",
    favorite: "Favorites",
    home: "Home",
    gallery: "Gallery",
    featured: "Featured",
    "my-favorites": "my-favorites",
    family: "For Family",
    wedding: "For Wedding",
    couple: "For cooples",
    mens: "Men's",
    womens: "Women's",
    Jewelries: "Jewelries",
    "5gmen": "5gada products for men",
    "5gwomen": "5gada products for women",
    "5gkid": "5gada products for kids",
  },
};

export const pathLang = {
  om: {
    buyer: "Maamila",
    seller: "Gurguraa",
    new: "Haarawa",
    kids: "Ijjoollef",
    "Men's": "Dhiiraf",
    Jewelries: "Faayaalee aadaa",
    "Women's": "Haadholeef",
    "my-favorites": "filanna ko",
    product: "Oomisha",
  },
  en: {
    buyer: "Buyer",
    new: "New",
    product: "Product",
    Jewelries: "Jewelries",
    kids: "Kids",
    "Men's": "Men's",
    "Women's": "Women's",
  },
};

export const actionLang = {
  om: {
    rateprod: "Oomisha Gamagamii",
    prodDesc: "Ibsa Oomishaa",
    viewProf: "Profaayilii Ilaali",

    call: "Bilbili",
  },
  en: {
    call: "Call",
    viewProf: "View Profile",
    rateprod: "Rate the Product",
    prodDesc: "Product Description",
  },
};

const error = {
  om: {
    dataloadinger: "",
  },
  en: {
    dataloadinger: "Error happend while loading data",
  },
};
