import React from "react";
import { NavBar } from "./SellerComp";

export default function ComingSoon() {
  return (
    <div>
      <NavBar />
      <div className="flex w-full">
        <img
          style={{
            minWidth: "100%",
          }}
          src="/images/soon.png"
          className="w-auto h-auto min-w-max"
          alt="Coming Soon"
        />
      </div>
    </div>
  );
}
