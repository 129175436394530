// Dashboard.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { APIURL } from "../../asset/host";
import { Card, CardImg, CardTitle } from "reactstrap";
import { TimeAgo } from "../../shared/TimeAgo";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import UserAction from "../product-interaction/UserAction";
import Rating from "../product-interaction/rating";
import { RateDispayer } from "../product-interaction/RatingsComponent";

function Dashboard() {
  const [username, setUsername] = useState("");
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${APIURL}/api/dashboard`,
          { withCredentials: true }
        );
        setUsername(response.data.user.username);
        //ff  console.log(response.data.user.username);
      } catch (error) {
        // navigate("/login");
        console.error("Fetching user data error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);
  function handleSidebarItemClick(item) {
    setSelectedItem(item);
  }
  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <div>
      <Navbar />
      <div className="dashboard-container">
        <Sidebar onItemClick={handleSidebarItemClick} />
        <MainDis element={selectedItem} />
      </div>
    </div>
  );
}
function Navbar() {
  return (
    <nav className="navBar-header">
      <div className="logo">Dashboard Logo</div>
      <div className="nav-links Login-signUp right-side-nav">
        <div>Home</div>
        <div>Help</div>
        <div className="user-info">Username</div>
      </div>
    </nav>
  );
}

function MainDis({ element }) {
  return (
    <main className="main-content">
      {element ? element : "Select an item from the sidebar"}
    </main>
  );
}

function CompanyVerification() {
  const [companies, setCompanies] = useState([]);
  const [selectedCompIndex, setSelectedCompIndex] = useState(null);
  useEffect(() => {
    axios.get(`${APIURL}/api/company/verify`).then((res) => {
      setCompanies(res.data.data);
    });
  }, []);

  return (
    <div className="company-list">
      <TransitionGroup>
        {companies.map((company, index) => (
          <CSSTransition key={index} timeout={300} classNames="card">
            <div>
              {" "}
              <Card onClick={() => setSelectedCompIndex(index)}>
                <div>
                  <b>{company.name}</b>
                  <div>
                    <span className="muted">Business type:</span>{" "}
                    {company.business_type}
                  </div>
                  <div className="muted">
                    requested {TimeAgo(company.created_at)}
                  </div>
                  <div className="muted">location {company.address}</div>
                  <div>
                    <span className="muted">description: </span>{" "}
                    {company.description}
                  </div>
                </div>
              </Card>
              {selectedCompIndex === index && (
                <DetailAbtCompany company={company} />
              )}
            </div>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </div>
  );
}
function DetailAbtCompany({ company }) {
  const [verified, setVerified] = useState(false);
  const [unverified, setUnverified] = useState(false);
  function Verify(value) {
    const company_id = company.id;
    const verify = value;
    const Data = { company_id, verify };
    axios
      .post(`${APIURL}/api/company/verify`, Data)
      .then((response) => {
        if (value) {
          setVerified(true);
        } else {
          setUnverified(true);
        }
      })
      .catch((error) => {});
  }
  return (
    <div className="company-detail">
      <Card>
        {" "}
        <h4>{company.name}</h4>
        {/*eslint-disable-next-line jsx-a11y/img-redundant-alt */}
        <img
          src={`${APIURL}/api/getImage/` + company.license}
          alt="company license image"
        />
        <br />
        <button onClick={() => Verify(true)} className="btn btn-primary">
          Verify
        </button>
        {verified ? (
          <span className="alert alert-success">successfully verified</span>
        ) : (
          ""
        )}
        <button onClick={() => Verify(false)} className="btn btn-danger">
          Remove
        </button>
        {unverified ? (
          <span className="alert alert-danger">successfully unverified</span>
        ) : (
          ""
        )}
      </Card>
    </div>
  );
}
function Post() {
  return (
    <>
      <Product filter={"latest"} />
    </>
  );
}
function Sidebar({ onItemClick }) {
  function handleVerificationClick() {
    onItemClick(<CompanyVerification />); // Call the handler with the selected item
  }
  return (
    <aside className="sidebar">
      <ul>
        <li>Analytics</li>
        <li onClick={() => onItemClick(<Post />)}>Posts</li>
        <li onClick={handleVerificationClick}>Companies Verification</li>
        <li>System Log</li>
      </ul>
    </aside>
  );
}

function Product({ filter }) {
  const [products, setProduct] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const cards = document.querySelectorAll(".fadeInAnimation");
    cards.forEach((card, index) => {
      setTimeout(() => {
        card.classList.add("visible");
      }, 50 * index);
    });
  }, [products]);
  let apiUrl = `${APIURL}/api/products?sort=latest`;
  if (filter !== "latest") {
    apiUrl = `${APIURL}/api/products?forWhom=${filter}&sort=latest`;
  }
  useEffect(() => {
    axios
      .get(apiUrl)
      .then((response) => {
        setProduct(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [apiUrl]);

  ///let products = ProductList.filter((prod) => prod.for === "child");
  const handleProductDetail = (product) => {
    navigate(`/buyer/product/${product.id}`);
  };
  // NEED TO BE EDITED
  const userId = 42;
  return (
    <>
      <div className="Card_list product-list">
        {products.length > 0 ? (
          products.map((product, key) => (
            <Card
              onClick={() => handleProductDetail(product)}
              key={key}
              className="fadeInAnimation"
            >
              <UserAction
                product={product}
                userId={userId}
                productId={product.id}
              />
              <CardImg
                src={`${APIURL}/api/getImage/` + product.filename}
                alt="not found"
              ></CardImg>

              <CardTitle>
                {product.title} <br />
                <b>{product.price} ETB</b> <br />
                <RateDispayer productId={product.id} /> <br />
                <span className="muted">{product.num_views} views</span> <br />
                <span className="muted">{TimeAgo(product.created_at)}</span>
                <br />
                {product.condition}
              </CardTitle>
            </Card>
          ))
        ) : (
          <h1>NO Product found</h1>
        )}
      </div>
    </>
  );
}

export default Dashboard;
