import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import ProductForm from "./productUploadComp";
import { Navbar } from "reactstrap";


export default function UploadFile() {
  const { usern } = useParams();
  return (
    <>
      {/* <Navbar /> */}
      <h3 className="Header-short-line">Posting Product</h3>
      <ProductForm />
    </>
  );
}
