import axios from "axios";
import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { APIURL } from "../../asset/host";

export default function PlaceSelector({ handlePlaceSelect }) {
  const [places, setPlaces] = useState([]);
  const [selectedPlaces, setSelectedPlaces] = useState([]);

  const handleInputChange = (selectedOptions) => {
    setSelectedPlaces(selectedOptions);
    handlePlaceSelect(selectedOptions.value);
  };

  const handleCreate = (inputValue) => {
    saveNewPlace(inputValue);
    handlePlaceSelect(inputValue);
  };

  const saveNewPlace = (newPlace) => {
    const updatedPlaces = [{ value: newPlace, label: newPlace }];
    setSelectedPlaces(updatedPlaces);

    axios
      .post(`${APIURL}/api/data/places`, { locations: newPlace })
      .then((response) => {})
      .catch((error) => {
        console.error("Error saving new place:", error);
      });
  };

  useEffect(() => {
    axios
      .get(`${APIURL}/api/data/places`)
      .then((res) => {
        setPlaces(res.data);
      })
      .catch((er) => {
        console.log(er);
      });
  }, []);

  const enumPlaces = places.map((category) => ({
    value: category,
    label: category,
  }));

  return (
    <div>
      <CreatableSelect
        onChange={handleInputChange}
        onCreateOption={handleCreate}
        styles={{ margin: "2px" }}
        name="Location"
        options={enumPlaces}
        className="basic-multi-select mr-2 gap-2"
        classNamePrefix="select"
        value={selectedPlaces}
        placeholder="Enter location here"
      />
    </div>
  );
}
