import React from "react";

export default function ProfileContainer({ content }) {
  return (
    <div>
      <div className=" p-">
        <div className="bg-white m-1 ml-2 mr-2 rounded p-1 text-2xl">
          {content.title}
        </div>
        {content.content}
      </div>
    </div>
  );
}
