import { BrowserRouter, Link, Route, Routes } from "react-router-dom";

import HomePage from "./pages/homePage";
import Buyer, {
  BuyerPage,
  Jewelries,
  MeeshaaAadaa,
  Men,
  New,
  Women,
  Childrens,
} from "./pages/BuyerPage";
import Seller from "./pages/SellerComp";
import { Login, Register } from "./components/Login_registration";
import SearchResult from "./components/search/Search";
import "./App.css";
import UploadFile from "./components/uploading/fileUploading";
import SellerCompany from "./components/users/company";
import Dashboard from "./components/admin/Dashboard";
import Footer from "./components/footer";
import About5gada from "./pages/About5gada";
import { useEffect, useState } from "react";
import axios from "axios";
import { APIURL } from "./asset/host";
import { useDispatch, useSelector } from "react-redux";
import {
  setCarts,
  setLanguage,
  setLoginStatus,
  setUserInfo,
  setUsername,
} from "./redux/actions";
import { GenericLittleLoadingModal } from "./shared/GenericModal";
import PhotoGallery from "./components/Gallery/Gallery";
import MyFavorite from "./components/product-interaction/favorite";
import { BuyerNav } from "./components/BuyerNav";
import User from "./components/users/User";
import ProductsPg from "./components/product/ProductsPg";
import Page404 from "./components/error/Page404";
import ComingSoon from "./pages/comingSoon";
import ProductDetail from "./components/product/productDetial";
import ScrollToTopOnNavigation from "./scroltop";

import { HelmetProvider } from 'react-helmet-async';
function RouteUser() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    let isLoggedIn = true;
    axios
      .get(`${APIURL}/api/user/me`, {
        withCredentials: true,
      })
      .then((res) => {
        dispatch(setLoginStatus(true));
        isLoggedIn = true;
        dispatch(setUsername(res.data.username));
        dispatch(setUserInfo(res.data));
        dispatch(setLanguage(res.data.language || "om"));
        // res.data[0].username
        // console.log(res.data[0])
        setLoading(false);
      })
      .catch((err) => {
        if (
          err.response.status === 401 ||
          err.response.status === 402 ||
          err.response.status === 403
        ) {
          dispatch(setLoginStatus(false));
        }
        setLoading(false);
      });
  }, []);

  const isLoggedIn = useSelector((state) => state.store.isLogin);
  useEffect(() => {
    if (isLoggedIn) {
      axios
        .get(`${APIURL}/api/products/user/carts`, {
          withCredentials: true,
        })
        .then((res) => {
          dispatch(setCarts(res.data.Carts.nums));
        })
        .catch((err) => {});
    }
  }, [isLoggedIn]);

  return (
    <>
      {loading ? (
        <GenericLittleLoadingModal isOpen={loading} />
      ) : (
        <BrowserRouter>
          <ScrollToTopOnNavigation />
          <Routes>
            <Route path="/soon" element={<ComingSoon />} />
            <Route exact path="/" element={<HomePage />} />
            <Route path="/*" element={<NotFound />} />
            {/* defualt pages */}
            <Route exact path="/buyer" element={<Buyer />} />
            <Route exact path="/seller" element={<Seller />} />
            <Route path="/search" element={<SearchResult />} />
            <Route exact path="/dashboard" element={<Dashboard />} />
            {/* user interaction pages */}
            <Route path="/login" element={<Login />} />
            <Route path="/user/new" element={<Register />} />

            <Route exact path="/:username" element={<User />} />
            {/* <Route path="/:username/profile" element={<Profile />} /> */}
            <Route path="/:username/company" element={<SellerCompany />} />
            {/* product and product type pages */}
            <Route
              path="/buyer/kids"
              element={<BuyerPage children={<Childrens />} />}
            />
            <Route
              path="/buyer/Men's"
              element={<BuyerPage children={<Men />} />}
            />
            <Route
              path="/buyer/Women's"
              element={<BuyerPage children={<Women />} />}
            />
            <Route
              path="/buyer/New"
              element={<BuyerPage children={<New />} />}
            />
            <Route
              path="/buyer/Jewelries"
              element={<BuyerPage children={<Jewelries />} />}
            />
            <Route
              path="/buyer/Meeshale-aadaa"
              element={<BuyerPage children={<MeeshaaAadaa />} />}
            />

            {/* detail */}
            <Route
              path="/buyer/product/:id"
              element={<BuyerPage children={<ProductDetail />} />}
            />
            <Route path="/buyer/product" element={<ProductsPg />} />
            {/* file uplading and accessing */}
            <Route path="/user/uploading/:username" element={<UploadFile />} />

            {/* ORDER */}
            <Route
              path="/:username/my-favorites"
              element={
                <>
                  <BuyerNav />
                  <MyFavorite />
                </>
              }
            />
            {/* FEATURES */}
            <Route path="/Gallery" element={<PhotoGallery />} />

            {/* more default pages */}
            <Route exact path="/about" element={<About5gada />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      )}
    </>
  );
}

function App() {
  useEffect(() => {
    if (window.innerWidth <= 767) {
      document.querySelector('meta[name="viewport"]').content =
        "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no";
    } else {
      document.querySelector('meta[name="viewport"]').content =
        "width=device-width, initial-scale=1";
    }
  }, []);

  return (
    <HelmetProvider>
      <div className="all-pg relative mb-16 sm:mb-1">
        <RouteUser />
      </div>
    </HelmetProvider>
  );
}

export default App;

function NotFound() {
  const handleBack = () => {
    window.history.back();
  };
  return (
    <div>
      <div className="flex-col justify-center text-center items-center">
        <div className=" w-96 mt-8 sm:mt-14 flex-col justify-center m-auto overflow-hidden h-96">
          <Page404 />
        </div>
        <div className=" text-3xl  font-bold">
          Oops! sorry this page not found
        </div>
        <Link to="/" className="btn no-underline bg-primary text-white">
          Go to home
        </Link>
        <button className="btn bg-gray-400 text-white" onClick={handleBack}>
          back
        </button>
      </div>
    </div>
  );
}
