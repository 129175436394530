import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import UserProfile from "./UserProfile";
import OtherUserProfile from "./OtherUserProfile";

export default function User() {
  const { username } = useParams();
  const loggedUsername = useSelector((state) => state.store.username);

  if (loggedUsername !== "" && loggedUsername === username) {
    return <UserProfile />;
  } else {
    return <OtherUserProfile />;
  }
}
