/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { NavBar } from "./SellerComp";

import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import {  useSelector } from "react-redux";
import { lang } from "../asset/language";

export default function About5gada() {
  // const [lang_change, setlang_change] = useState("om");
  const [selectedlang, setSelectedLang] = useState({});
  const lang_change = useSelector((state) => state.store.language);

  useEffect(() => {
    if (lang_change === "om") {
      setSelectedLang(lang.om);
    } else {
      setSelectedLang(lang.en);
    }
  }, [lang_change]);

  return (
    <>
      <Helmet>
        {/* 1. Page Title */}
        <title>{selectedlang.abt_title}</title>

        {/* 2. Meta Description */}
        <meta
          name="description"
          content="Explore Oromo cultural products and services on 5gada. Connect with Oromo tailors, designers, and enthusiasts."
        />

        {/* 3. Canonical Tag */}
        <link rel="canonical" href="https://5gada.com/about" />

        {/* 4. Open Graph and Twitter Cards */}
        <meta property="og:title" content="5gada - Celebrating Oromo Culture" />
        <meta
          property="og:description"
          content="Explore Oromo cultural products and services on 5gada. Connect with Oromo tailors, designers, and enthusiasts."
        />
        <meta property="og:image" content="/images/logo15.png" />
        <meta property="og:url" content="https://5gada.com/about" />
        <meta name="twitter:card" content="summary_large_image" />

        {/* 5. Structured Data */}
        <script type="application/ld+json">
          {`
          {
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": "5gada",
            "description": "Celebrating Oromo Culture through tailor and designer services.",
            "url": "https://5gada.com",
            "logo": "/images/logo.png"
          }
        `}
        </script>
      </Helmet>

      <NavBar />
      
      <section className="py-16 bg-cover bg-center bg-primary text-primary-dark">
        <div className="container mx-auto flex-col sm:flex-row sm:flex items-center justify-center">
          <div className="sm:w-full max-h-min flex-col justify-center text-center mb-6 sm:mb-0">
            <h1 className="text-4xl  mb-4">{selectedlang.welcome25gada}</h1>
            <p className="text-lg">{selectedlang.abt_bannerp}</p>
          </div>
          <div className="sm:w-5/6 sm:h-96 overflow-hidden rounded-md shadow-lg">
            <img
              className="w-full h-full object-cover object-left-bottom"
              src="images/about/gume.jpg"
              alt="Welcome Image"
            />
          </div>
        </div>
      </section>
      <section className="py-1 px-1 shadow-sm sm:shadow-none">
        <div className="container mx-auto  px-1 ">
          <h2 className="text-4xl font-bold mb-4">{selectedlang.abt5g}</h2>
          <p className="text-lg">{selectedlang.abt5gp}</p>
        </div>
      </section>

      <section className="py-1 px-1 shadow sm:shadow-none">
        <div className="container  px-1 mx-auto flex flex-col md:flex-row items-center justify-center border-b-2">
          <div className="md:w-2/5">
            <img
              className="w-full rounded-lg shadow"
              src="/images/about/promote.png"
              alt="Promotion Illustration"
            />
          </div>
          <div className="md:w-3/4  md:ml-8  md:mt-0">
            <h2 className="text-4xl font-bold mb-6 text-gray-800">
              {selectedlang.discover_service}
            </h2>
            <p className="text-lg text-gray-600 mb-6">
              {selectedlang.disc_msg}
            </p>
            <div className="flex sm:inline flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
              <Link className="btn btn-primary no-underline" to="/buyer">
                {selectedlang.exp_pro}
              </Link>
              <Link className="btn btn-secondary no-underline" to="/seller">
                {selectedlang.prom_u_serv}
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="py-1 px-1">
        <div className="container px-1 sm:mx-auto flex flex-col md:flex-row items-center justify-center ">
          <div className="mb-8">
            <h2 className="text-4xl font-bold text-gray-800">
              {" "}
              {selectedlang.discover_fashn}
            </h2>
            <p className="text-lg">{selectedlang.gal_msg}</p>
            <Link className="btn btn-secondary no-underline" to="/gallery">
              {selectedlang.exp_gall}
            </Link>
          </div>
          <div className="md:w-3/5">
            <img
              className="w-full  rounded-md shadow"
              src="/images/about/collection.png"
              alt="Collection Section"
            />
          </div>
        </div>
      </section>

      <section className="py-1 shadow sm:shadow-none bg-primary bg-opacity-20">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-bold mb-8">{selectedlang.k_feat}</h2>
          <div className="flex flex-wrap justify-center">
            {/* Feature Card 1 */}
            <div className="w-full md:w-1/2 lg:w-1/3 p-4">
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-bold mb-2">
                  {selectedlang.feat1h}
                </h3>
                <p className="text-gray-700">{selectedlang.feat1p}</p>
              </div>
            </div>

            {/* Feature Card 2 */}
            <div className="w-full md:w-1/2 lg:w-1/3 p-4">
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-bold mb-2">
                  {selectedlang.feat2h}
                </h3>
                <p className="text-gray-700">{selectedlang.feat2p}</p>
              </div>
            </div>

            {/* Feature Card 3 */}
            <div className="w-full md:w-1/2 lg:w-1/3 p-4">
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-bold mb-2">
                  {selectedlang.feat3h}
                </h3>
                <p className="text-gray-700">{selectedlang.feat3p}</p>
              </div>
            </div>

            {/* Feature Card 4 */}
            <div className="w-full md:w-1/2 lg:w-1/3 p-4">
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-bold mb-2">
                  {selectedlang.feat4h}
                </h3>
                <p className="text-gray-700">{selectedlang.feat4p}</p>
              </div>
            </div>

            {/* Feature Card 5 */}
            <div className="w-full md:w-1/2 lg:w-1/3 p-4">
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-bold mb-2">
                  {selectedlang.feat5h}
                </h3>
                <p className="text-gray-700">{selectedlang.feat5p}</p>
              </div>
            </div>

            {/* Feature Card 6 */}
            <div className="w-full md:w-1/2 lg:w-1/3 p-4">
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-bold mb-2">
                  {selectedlang.feat6h}
                </h3>
                <p className="text-gray-700">{selectedlang.feat6p}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-1 px-2 bg-primary bg-opacity-25 text-gray-800">
        <div className="container  mx-auto flex flex-col md:flex-row items-center justify-center">
          <div>
            {" "}
            <h2 className="text-3xl font-bold mb-8">Our Mission and Values</h2>
            <p className="text-lg">
              At 5gada, we are committed to preserving and promoting Oromo
              culture. Our values include:
            </p>
            <ul className="list-disc text-left">
              <li className="mb-2">Cultural Preservation</li>
              <li className="mb-2">Community Collaboration</li>
              <li className="mb-2">Inclusivity and Diversity</li>
              <li className="mb-2">Promoting Oromo Culture</li>
              <li className="mb-2">
                Creating Design and Oromo's Product Collection
              </li>
              <li className="mb-2">Showcasing Oromo to the World</li>
            </ul>
            <p className="text-lg mt-4">
              Join us in creating a vibrant community that celebrates diversity
              and creativity.
            </p>
          </div>
          <div>
            <img src="/images/about/mission-removebg-preview.png" />
          </div>
        </div>
      </section>

      <section className="py-1 px-2">
        <div className="container mx-auto ">
          <h2 className="text-3xl font-bold mb-8">Contact Us</h2>
          <p className="text-lg m-0">
            Have feedback or suggestions? Reach out to us at
            <a className="ml-3" href="mailto:feedback@5gada.com">
              feedback@5gada.com.
            </a>
          </p>
          <p className="text-lg m-0 pt-0">
            Have questions or support? Reach out to us at
            <a className="ml-3" href="mailto:support@5gada.com">
              support@5gada.com.
            </a>
          </p>
        </div>
      </section>
    </>
  );
}
