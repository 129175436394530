import { CardImg } from "reactstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useReducer, useState } from "react";
import { SearchFilters } from "./SearchFilters";
import axios from "axios";
import Loading from "../Loading";
import { APIURL } from "../../asset/host";
import { RateDispayer } from "../product-interaction/RatingsComponent";
import Cart from "../product-interaction/cart/cart";
import { LoadingCardList, PreLoading } from "../../shared/LoadingCard";
import InfiniteScroll from "react-infinite-scroll-component";
import { TimeAgo } from "../../shared/TimeAgo";
import { BuyerNav } from "../BuyerNav";
import Like from "../product-interaction/like";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useSelector } from "react-redux";
import { lang } from "../../asset/language";

export function SearchDisplayer({ product, handleClose }) {
  const [companyInfo, setCompanyInfo] = useState([]);
  const [shouldAnimate, setShouldAnimate] = useState(false);
  useEffect(() => {
    axios
      .get(`${APIURL}/api/company/${product.company_id}`)
      .then((response) => {
        setCompanyInfo(response.data);
      })
      .catch((error) => {
        console.error("Error updating view count:", error);
      });
  }, [product]);

  const navigate = useNavigate();
  useEffect(() => {
    setShouldAnimate(true);
  }, []);

  const handleProductDetail = (product) => {
    navigate(`/buyer/product/${product.id}`);
  };

  const langType = useSelector((state) => state.store.language);
  return (
    <>
      <div
        className={`Search-result-displayer ${
          shouldAnimate ? "transition-animation" : ""
        }`}
      >
        <div
          className="result_product"
          onClick={() => {
            handleProductDetail(product);
            if (handleClose) handleClose();
          }}
        >
          <LazyLoadImage
            effect="blur"
            className="w-full object-cover object-center"
            width={"100%"}
            alt={product.title}
            height={"100%"}
            src={`${APIURL}/api/getImage/` + product.filename}
            placeholderSrc={`${APIURL}/api/getImage/` + product.filename}
          />
        </div>
        <div className="result_detail">
          <div className="result_detail_info">
            <Link
              to={`/buyer/product/${product.id}`}
              onClick={() => {
                handleProductDetail(product);
                if (handleClose) handleClose();
              }}
              title={product.title + "from " + companyInfo.name}
              className="link-primary"
            >
              {product.title}
            </Link>
            <div className="price">{product.price} ETB</div>

            <div className="muted">{TimeAgo(product.created_at, langType)}</div>
          </div>
          <div className="result_detail_seller_info">
            <div className="Seller-detail-info muted">
              <span
                title={"Joined " + TimeAgo(companyInfo.joinedDate, langType)}
              >
                {langType === "om"
                  ? TimeAgo(companyInfo.joinedDate, langType) + " Gale"
                  : "Joined " + TimeAgo(companyInfo.joinedDate, langType)}
              </span>
              <span title={`is from ${product.location}`}>
                | {product.location.toUpperCase().slice(0, 3)}
              </span>
              <Link
                to={`/${companyInfo.username}`}
                className="link-primary"
                title="owner of the product"
              >
                | {companyInfo.name}
              </Link>
            </div>
            <div className="rate muted">
              {product.num_views} views |{" "}
              <RateDispayer productId={product.id} />
            </div>
            <div className="buyer-action-buttons">
              <Link
                to={`/${companyInfo.username}`}
                className="btn btn-primary btn-default no-underline"
              >
                {lang[langType].Contact_Supplier}
              </Link>

              <span>
                <Cart
                  product={product}
                  productId={product.id}
                  placeholder={true}
                />
              </span>
              <span>
                <Like noCounter={true} productId={product.id} />
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default function SearchResult() {
  // const { query } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const updatedSearchParams = searchParams.toString();
  const query = searchParams.get("q");
  const [loading, setLoading] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    const apiUrl = `${APIURL}/api/products?${updatedSearchParams}&page=${page}`;

    axios
      .get(apiUrl)
      .then((response) => {
        setSearchResults(response.data);
        const newProducts = response.data;
        if (newProducts.length === 0 || newProducts.length < 10) {
          setHasMore(false);
        } else {
          setPage((prevPage) => prevPage + 1);
        }
        setLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
      });
  }, [updatedSearchParams]);

  const fetchMoreData = () => {
    if (hasMore) {
      const apiUrl = `${APIURL}/api/products?${updatedSearchParams}&page=${page}`;

      axios
        .get(apiUrl)
        .then((response) => {
          setSearchResults((prevProducts) => [
            ...prevProducts,
            ...response.data,
          ]);
          const newProducts = response.data;
          if (newProducts.length === 0 || newProducts.length < 10) {
            setHasMore(false);
          } else {
            setPage((prevPage) => prevPage + 1);
          }
          setLoading(false);
        })
        .catch((err) => {
          // console.log(err);
          setLoading(false);
        });
    }
  };

  const [shouldAnimate, setShouldAnimate] = useState(false);
  useEffect(() => {
    setShouldAnimate(true);
  }, []);

  return (
    <>
      <BuyerNav />
      <div className=" search-engine-page">
        <div className=" block">
          <SearchFilters searchQuery={query} />
        </div>
        <div className="search-engine-result">
          {loading ? (
            <>
              <PreLoading n={10} children={<LoadingCardList />} />
            </>
          ) : searchResults.length > 0 ? (
            <InfiniteScroll
              dataLength={searchResults?.length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={<Loading />}
            >
              {searchResults.map((result, key) => (
                <div
                  key={key}
                  style={{
                    transitionDelay: shouldAnimate
                      ? `${(key + 6) * 0.1}s`
                      : "0s",
                  }}
                >
                  {loading ? <Loading /> : <SearchDisplayer product={result} />}
                </div>
              ))}
            </InfiniteScroll>
          ) : (
            <div className="m-2 alert-warning p-4 rounded-lg">
              Not found any product with name '{query}'
            </div>
          )}
        </div>
      </div>
    </>
  );
}
