import React, { useState } from "react";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/styles.css";
import { useLocation, useNavigate } from "react-router-dom";

export default function SearchFilter({ handleSetFilter }) {
  const menuItems = [
    "All",
    "Design Uffataa",
    "Oromo",
    "Irrecha",
    "Odaa",
    "Gadaa",
    "Aadaa Booranaa",
    "gujii",
  ];
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const [activeItem, setActiveItem] = useState(null);

  const MenuItem = ({ text }) => {
    const isActive = activeItem === text;
    const handleClick = () => {
      setActiveItem(text);
      searchParams.set("filter", text);
      const updatedSearchParams = searchParams.toString();
      const newURL = `/gallery/?${updatedSearchParams}`;
      navigate(newURL);
    };

    return (
      <div
        className={`btn  ${
          text === activeItem
            ? "bg-primary text-white"
            : "hover:bg-primary hover:bg-opacity-35 "
        }`}
        onClick={handleClick}
      >
        {text}
      </div>
    );
  };

  const menu = menuItems.map((item, index) => (
    <MenuItem text={item} key={index} />
  ));

  return <ScrollMenu>{menu}</ScrollMenu>;
}
