import React, { useEffect, useRef, useState } from "react";
import { BsGlobe } from "react-icons/bs"; // Importing a globe icon from react-icons
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../redux/actions";
import axios from "axios";
import { APIURL } from "../asset/host";

export default function LanguageSelector() {
  const langType = useSelector((state) => state.store.language);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const buttonRef = useRef(null);
  useEffect(() => {
    if (langType === "om") {
      setSelectedLanguage({ value: "om", label: "Oromo" });
    } else {
      setSelectedLanguage({ value: "en", label: "English" });
    }
  }, [langType]);

  const languageOptions = [
    { value: "en", label: "English" },
    { value: "om", label: "Oromo" },
  ];

  const handleToggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };
  const dispatch = useDispatch();
  const handleClickOutside = (event) => {
    if (buttonRef.current && !buttonRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };
  const isLoggedIn = useSelector((state) => state.store.isLogin);
  const handleSelectLanguage = (language) => {
    setSelectedLanguage(language);
    setDropdownVisible(false);
    console.log("selected land", language);
    dispatch(setLanguage(language.value));
    // You can add logic here to handle language selection
    if (isLoggedIn)
      axios
        .put(
          `${APIURL}/api/users/lang`,
          { lang: language.value },
          {
            withCredentials: true,
          }
        )
        .then((res) => {})
        .catch((er) => {
          console.log(er);
        });
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative block" ref={buttonRef}>
      <span
        id="languageSelectorButton"
        className="font-medium text-md py-2.5 text-center cursor-pointer hover:font-bold inline-flex items-center border"
        onMouseEnter={handleToggleDropdown}
        onClick={handleToggleDropdown}
      >
        <BsGlobe />
        {selectedLanguage ? selectedLanguage.value : "en"}
      </span>

      {dropdownVisible && (
        <div
          id="languageDropdown"
          className="z-50 fixed bg-white text-black divide-gray-100 rounded-lg shadow-md"
          onMouseLeave={() => setDropdownVisible(false)}
        >
          <ul className="p-2 text-sm cursor-pointer flex flex-col select-none">
            {languageOptions.map((language) => (
              <li
                key={language.value}
                onClick={() => handleSelectLanguage(language)}
                className="block px-4 py-2 hover:bg-gray-100"
              >
                {language.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
