export const setCarts = (newCart) => ({
  type: "SET_CART",
  payload: newCart,
});

export const setListView = (isList) => ({
  type: "SET_VIEW_TYPE",
  payload: isList,
});

export const setLoginStatus = (isLogin) => ({
  type: "SET_LOGIN_STATUS",
  payload: isLogin,
});

export const setUsername = (username) => ({
  type: "SET_USERNAME",
  payload: username,
});

export const setUserInfo = (userInfo) => ({
  type: "SET_USERINFO",
  payload: userInfo,
});

export const setBackBtn = (backBtn) => ({
  type: "SET_BACK_BTN",
  payload: backBtn,
});

export const setSetIsMobile = (isMobile) => ({
  type: "SET_MOBILE_VIEW",
  payload: isMobile,
});

export const setLanguage = (language) => ({
  type: "SET_LANGUAGE",
  payload: language,
});
