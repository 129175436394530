import React, { useEffect, useState } from "react";
import { NavBar } from "../../pages/SellerComp";
import { Card, CardImg, CardTitle } from "reactstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { RateDispayer } from "../product-interaction/RatingsComponent";
import { APIURL } from "../../asset/host";
import { useDispatch, useSelector } from "react-redux";
import { setLoginStatus } from "../../redux/actions";
import {
  LoadingCardList,
  LoadingCardVert,
  PreLoading,
} from "../../shared/LoadingCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faHeart,
  faPencilAlt,
  faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";
import ProfileContainer from "./profileContainer";
import MyFavorite from "../product-interaction/favorite";
import { EditButton } from "./EditProfile";
import { IoIosCreate, IoMdCreate } from "react-icons/io";
import { TimeAgo } from "../../shared/TimeAgo";
import UserAction from "../product-interaction/UserAction";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export function CompanyProduct({ filter }) {
  const [loading, setLoading] = useState(true);
  const [products, setProduct] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const cards = document.querySelectorAll(".fadeInAnimation");
    cards.forEach((card, index) => {
      setTimeout(() => {
        card.classList.add("visible");
      }, 50 * index);
    });
  }, [products]);

  useEffect(() => {
    let apiUrl = `${APIURL}/api/products/companyName/${filter}`;

    axios
      .get(apiUrl)
      .then((response) => {
        setProduct(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [filter]);

  ///let products = ProductList.filter((prod) => prod.for === "child");
  const handleProductDetail = (product) => {
    navigate(`/buyer/product/${product.id}`);
  };

  const langType = useSelector((state) => state.store.language);
  return (
    <>
      {loading ? (
        <div className="Card_list product-list">
          <PreLoading n={6} children={<LoadingCardVert />} />
        </div>
      ) : products.length > 0 ? (
        <div className="Card_list product-list">
          {products.map((product, key) => (
            <Card
              onClick={() => handleProductDetail(product)}
              key={key}
              className="fadeInAnimation"
            >
              <LazyLoadImage
                className=" h-full w-full object-cover object-center "
                alt={product.title}
                effect="blur"
                delayTime={3000}
                src={`${APIURL}/api/getImage/` + product.filename}
                height={"50%"}
                width={"100%"}
                placeholderSrc={`${APIURL}/api/getImage/` + product.filename}
              />
              <UserAction product={product} productId={product.id} />

              <CardTitle>
                {product.title} <br />
                <span>{product.price}</span> <br />
                <RateDispayer productId={product.id} /> <br />
                <span className="muted">
                  {product.num_reviews} reviews
                </span>{" "}
                <br />
                <span className="muted">{TimeAgo(product.created_at, langType)}</span>
                <br />
                {product.condition}
              </CardTitle>
            </Card>
          ))}
        </div>
      ) : (
        <h1>NO Product found</h1>
      )}
    </>
  );
}

export function UserProfileDetail({ userInfo, notMe }) {
  const navigate = useNavigate();
  const handleLogoutConfirmed = async () => {
    try {
      await axios.get(`${APIURL}/api/authenticate/logout`, {
        withCredentials: true,
      });

      navigate("/");
      window.location.reload();
    } catch (error) {
      console.error("Logout failed", error);
    }
  };
  return (
    <div className="seller-profile-header">
      <div className="profile-info Card_list">
        <div className="profile-pic seler-pic align-left border">
          <CardImg src="/images/user-icon-placeholder-1.png" />{" "}
        </div>
        <div className="user-detail">
          <div className="uppercase text-sm">{userInfo.fullName}</div>
          <div className="muted">{userInfo.username}</div>

          {userInfo.userType === "seller" ? (
            <>
              <div className="muted text-capitalize">
                <CardImg
                  style={{
                    width: "16px",
                    marginLeft: "1px",
                    transform: "translateY(2px)",
                  }}
                  src="/images/company.png"
                />
                {!userInfo.company ? (
                  <span>No company</span>
                ) : (
                  <span> {userInfo.company}</span>
                )}
              </div>
              <div className="muted text-capitalize">
                <CardImg
                  style={{
                    width: "16px",
                    marginLeft: "1px",
                    transform: "translateY(2px)",
                  }}
                  src="/images/loca.png"
                />{" "}
                {userInfo.location}
              </div>
            </>
          ) : (
            <span
              title="you are buyer"
              className=" cursor-pointer bg-primary-light bg-opacity-70 hover:bg-opacity-90"
            >
              {userInfo.userType}
            </span>
          )}
          {notMe && (
            <div className="muted m-0 p-0">
              joined
              <span>
                {new Date(userInfo.joinedDate).toLocaleString("en-US", {
                  month: "long",
                  year: "numeric",
                })}
              </span>
            </div>
          )}

          {/* <div className="muted">
            Verified
            <CardImg
              style={{
                width: "16px",
                marginLeft: "1px",
              }}
              src="/images/verified.png"
            />
          </div> */}
          {!notMe && (
            <>
              <div>
                <EditButton userData={userInfo} />
              </div>
              <span
                className="rounded bg-slate-300  pr-2 pl-1 cursor-pointer font-bold"
                onClick={handleLogoutConfirmed}
              >
                logout
              </span>{" "}
            </>
          )}
        </div>

        <div
          style={{
            width: "350px",
          }}
        >
          {/* <CardImg src="/images/Analytics.png" /> */}
        </div>
      </div>
    </div>
  );
}

function UserProfile() {
  const [userInfo, setUserInfo] = useState([]);
  const navigate = useNavigate();
  const username = useSelector((state) => state.store.username);
  const [Loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const handlePostClick = (product) => {
    navigate(`/user/uploading/${username}`);
  };
  const handleCompanyClick = (product) => {
    navigate(`/${username}/company`);
  };

  useEffect(() => {
    axios
      .get(`${APIURL}/api/user/me`, {
        withCredentials: true,
      })
      .then((response) => {
        if (response.data.userType === "seller") fetchUser();
        else setUserInfo(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        // navigate("/login");
        dispatch(setLoginStatus(false));
      });
  }, []);

  const [activeButton, setActiveButton] = useState("My Products");

  const handleButtonClick = (title) => {
    setActiveButton(title);

    if (title === "My Products") {
      setProfileContainer({
        title: "My Products",
        content: <CompanyProduct filter={userInfo.company} />,
      });
    } else if (title === "My Favorites") {
      setProfileContainer({
        title: "My Favorites",
        content: <MyFavorite />,
      });
    }
  };

  const fetchUser = () => {
    setLoading(true);
    axios
      .get(`${APIURL}/api/seller/dashboard`, {
        withCredentials: true,
      })
      .then((response) => {
        setUserInfo(response.data.user);

        setLoading(false);
      })
      .catch((err) => {
        if (
          err.response.status === 401 ||
          err.response.status === 403 ||
          err.response.status === 402
        ) {
          setLoading(false);
          // navigate("/login");
          dispatch(setLoginStatus(false));
        } else {
          console.error("Error setting up the request:", err.message);
        }
      });
  };

  useEffect(() => {
    if (userInfo.userType === "seller") {
      setActiveButton("My Products");
      setProfileContainer({
        title: "My Products",
        content: <CompanyProduct filter={userInfo.company} />,
      });
    } else {
      setActiveButton("My Favorites");
      setProfileContainer({
        title: "My Favorites",
        content: <MyFavorite />,
      });
    }
  }, [userInfo]);

  const [profileContainer, setProfileContainer] = useState(null);

  return (
    <>
      <NavBar />
      {!Loading ? (
        <>
          <UserProfileDetail userInfo={userInfo} />

          <div
            style={{
              backgroundColor: "var(--bg-wt)",
              borderRadius: "8px",
              padding: "3px",
              margin: "5px",
            }}
            className="flex sm:block justify-center"
          >
            {userInfo.userType === "seller" ? (
              <>
                <button
                  onClick={() => handleButtonClick("My Products")}
                  className={`btn flex justify-center align-middle ${
                    activeButton === "My Products"
                      ? "bg-primary text-white"
                      : "bg-gray-300"
                  }`}
                >
                  <FontAwesomeIcon
                    icon={faShoppingCart}
                    className="mr-1 text-2xl sm:text-lg"
                  />
                  <div className="hidden sm:inline">My Products</div>
                </button>
                <button
                  onClick={handlePostClick}
                  className="btn flex items-center bg-gray-300"
                >
                  <IoIosCreate className="text-2xl sm:text-lg" />
                  <div className="hidden sm:inline">Post new product</div>
                </button>
              </>
            ) : (
              <></>
            )}
            <button
              onClick={() => handleButtonClick("My Favorites")}
              className={`btn flex justify-center align-middle ${
                activeButton === "My Favorites"
                  ? "bg-primary text-white"
                  : "bg-gray-300"
              }`}
            >
              <FontAwesomeIcon
                icon={faHeart}
                className="mr-1 text-2xl sm:text-lg"
              />

              <div className="hidden sm:inline"> my favorites</div>
            </button>

            {!userInfo.company && (
              <button onClick={handleCompanyClick} className="btn bg-gray-300">
                <FontAwesomeIcon icon={faPencilAlt} />
                Create business profile
              </button>
            )}
          </div>

          {profileContainer && <ProfileContainer content={profileContainer} />}
        </>
      ) : (
        <div className="seller-profile-header bg-white overflow-hidden">
          <LoadingCardList />
        </div>
      )}
    </>
  );
}

export default UserProfile;
