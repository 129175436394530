import React, { useEffect, useState } from "react";
import axios from "axios";
import CreatableSelect from "react-select/creatable";
import { APIURL } from "../../asset/host";

export const CreatorComponent = ({ handleTagCreation }) => {
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const fetchTags = () => {
    axios
      .get(`${APIURL}/api/data/tags`)
      .then((res) => {
        setTags(res.data);
      })
      .catch((er) => {
        console.log(er);
      });
  };

  useEffect(() => {
    fetchTags();
  }, []);

  const enumCategoriesOptions = tags.map((category) => ({
    value: category,
    label: category,
  }));

  const handleChange = (selectedOptions) => {
    setSelectedTags(selectedOptions);
    handleTagCreation(selectedOptions.map(option => option.value));
  };

  const handleCreate = (inputValue) => {
    const newTag = inputValue.trim();

    // Assuming you want to keep the existing selected tags and add the new one
    const updatedTags = [...selectedTags, { value: newTag, label: newTag }];
    setSelectedTags(updatedTags);

    axios
      .post(`${APIURL}/api/data/tags`, { tag: newTag })
      .then(() => {
        fetchTags(); // Optionally, you can update the tags list after creating a new tag
      })
      .catch((error) => {
        console.error("Error creating tag:", error);
      });

    handleTagCreation(updatedTags.map(tag => tag.value));
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      marginRight: "3px",
      padding: "6px",
      gap: "3px",
    }),
  };

  const NoOptionsMessage = ({ inputValue }) => (
    <div className="react-select-no-options">
      No matching options found for `{inputValue}`.
    </div>
  );

  return (
    <CreatableSelect
      isMulti
      styles={customStyles}
      options={enumCategoriesOptions}
      placeholder="Create or select tags"
      onChange={handleChange}
      onCreateOption={handleCreate}
      isSearchable
      components={{ NoOptionsMessage }}
      isCreatable={false}
      className="z-100 mr-3 w-full"
      value={selectedTags}
    />
  );
};
