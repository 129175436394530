import React from "react";
import { Link } from "react-router-dom";

export default function GalleryHeader() {
  return (
    <div className="navBar-header">
      <div className="logo">
        <Link to="/">
          <img alt="logo" src="/images/logo15.png" />
        </Link>
      </div>
      <div className="gallery-title">
        <h1 className="Header-short-line">
          <div className="word">
            G<span className="red-gradient">al</span>l
            <span className="green-gradient">eri</span>es
          </div>
        </h1>
      </div>
    </div>
  );
}
