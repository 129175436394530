import { Link, useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, CardHeader, CardImg } from "reactstrap";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { APIURL } from "../asset/host";
import GenericModal from "../shared/GenericModal";
import { useDispatch, useSelector } from "react-redux";
import { setUsername } from "../redux/actions";
import { GrGallery } from "react-icons/gr";
import { GoHomeFill } from "react-icons/go";
import { IoIosInformationCircle } from "react-icons/io";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { ServiceLang, lang, pathLang } from "../asset/language";
import LanguageSelector from "../components/LanguageSelector";

export function NavBar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoggedIn, setLoggedIn] = useState(true);
  useEffect(() => {
    axios
      .get(`${APIURL}/api/user/me`, {
        withCredentials: true,
      })
      .then((res) => {
        setLoggedIn(true);
        dispatch(setUsername(res.data.username));
      })
      .catch((err) => {
        if (
          err.response.status === 401 ||
          err.response.status === 402 ||
          err.response.status === 403
        ) {
          setLoggedIn(false);
        }
      });
  }, []);
  const username = useSelector((state) => state.store.username);
  const langType = useSelector((state) => state.store.language);
  return (
    <>
      <div id="myHeader" className="navBar-header" style={{}}>
        <div className="logo">{/* <img src="/images/logo15.png" /> */}</div>

        <div className="nav-menu">
          <nav className="right-side">
            <ul className="flex items-center list-none ">
              <li>
                <Link className="link flex items-center" to="/">
                  <GoHomeFill className="text-2xl" />
                  <span className="mobile_hidden">
                    {ServiceLang[langType].home}
                  </span>
                </Link>
              </li>
              <li>
                <Link className="link flex items-center" to="/about">
                  <IoIosInformationCircle className="text-2xl" />
                  <span className="mobile_hidden">{lang[langType].about}</span>
                </Link>
              </li>

              <li className="help">
                <Link className="link flex items-center" to="/gallery">
                  <GrGallery className="text-xl mr-0.5" />
                  {ServiceLang[langType].gallery}
                </Link>
              </li>
            </ul>
          </nav>
          <div >
            <LanguageSelector />
          </div>
          <div className="Login-signUp right-side-nav">
            {isLoggedIn ? (
              <div
                className="user-pr"
                onClick={() => {
                  if (username) navigate(`/${username}`);
                }}
              >
                <img
                  alt="placeholder"
                  src="/images/user-icon-placeholder-1.png"
                />
              </div>
            ) : (
              <>
                <Link to="/login" className="btn btn-secondary">
                  Login
                </Link>
                <Link to="/user/new" className="btn btn-primary">
                  signUp
                </Link>
              </>
            )}{" "}
          </div>
        </div>
      </div>
    </>
  );
}

function HomeBody() {
  const langType = useSelector((state) => state.store.language);
  return (
    <>
      <h1 className="Header-short-line">{lang[langType].top_prodh1} </h1>
      <div className="Card_list product-category">
        <Card>
          <Link to="/buyer/Jewelries">
            <LazyLoadImage
              effect="blur"
              className="w-full object-cover object-center"
              width={"100%"}
              height={"100%"}
              src="/images/trying/calle.jpg"
              placeholderSrc="/images/trying/calle.jpg"
            />

            <div className="detailed-info">
              Callee fi faayalee
              <div>
                <button className="btn btn-secondary">
                  {lang[langType].see_more}
                </button>
              </div>
            </div>
          </Link>
        </Card>
        <Card>
          <Link to="/buyer/kids">
            <LazyLoadImage
              effect="blur"
              className="w-full object-cover object-center"
              width={"100%"}
              alt="uffata aadaa ijjoollee"
              height={"100%"}
              src="/images/trying/ijjoollee.jpg"
              placeholderSrc="/images/trying/ijjoollee.jpg"
            />

            <div className="detailed-info">
              Kan ijjoollee
              <div>
                <button className="btn btn-secondary">
                  {lang[langType].see_more}
                </button>
              </div>
            </div>
          </Link>
        </Card>
        <Card>
          <Link to="/buyer/women's">
            <LazyLoadImage
              effect="blur"
              className="w-full object-cover object-center"
              width={"100%"}
              alt="uffata aadaa dubartii"
              height={"100%"}
              src="/images/trying/dubartii.jpg"
              placeholderSrc="/images/trying/dubartii.jpg"
            />
            <div className="detailed-info">
              Kan haadholeef durboota
              <div>
                <button className="btn btn-secondary">
                  {lang[langType].see_more}
                </button>
              </div>
            </div>
          </Link>
        </Card>
        <Card>
          <Link to="/buyer/men's">
            <LazyLoadImage
              effect="blur"
              className="w-full object-cover object-center"
              width={"100%"}
              alt="uffata aadaa ijjoollee"
              height={"100%"}
              src="/images/trying/dhira.jpg"
              placeholderSrc="/images/trying/dhira.jpg"
            />
            <div className="detailed-info">
              Kan dhiiraa
              <div>
                <button className="btn btn-secondary">
                  {lang[langType].see_more}
                </button>
              </div>
            </div>
          </Link>
        </Card>
        <Card>
          <CardImg src="/images/trying/calle.jpg" alt="not found"></CardImg>{" "}
          <LazyLoadImage
            effect="blur"
            className="w-full object-cover object-center"
            width={"100%"}
            alt="uffata aadaa ijjoollee"
            height={"100%"}
            src="/images/trying/calle.jpg"
            placeholderSrc="/images/trying/calle.jpg"
          />
          <div className="detailed-info">
            Callee faaya oromo{" "}
            <div>
              <button className="btn btn-secondary">
                {lang[langType].see_more}
              </button>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
}
function Events() {
  return (
    <>
      <hr />
      <h3 className="Header-short-line">Events</h3>
      <div className="Events-section Events-landing">
        <Card>
          <CardHeader>
            5Gada is best online selling and buying platform
          </CardHeader>
          <CardBody>
            <p>
              for individuals and businesses alike. With its advanced technology
              and seamless user experience, 5Gada offers a wide range of
              products and services. Whether you're searching for electronics,
              fashion, home decor, or more, 5Gada has it all. The platform
              ensures secure transactions and reliable delivery, making it a
              trusted choice for online shoppers. Experience the convenience and
              efficiency of 5Gada for all your online buying and selling needs.
            </p>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            5Gada is best online selling and buying platform
          </CardHeader>
          <CardBody>
            <p>
              for individuals and businesses alike. With its advanced technology
              and seamless user experience, 5Gada offers a wide range of
              products and services. Whether you're searching for electronics,
              fashion, home decor, or more, 5Gada has it all. The platform
              ensures secure transactions and reliable delivery, making it a
              trusted choice for online shoppers. Experience the convenience and
              efficiency of 5Gada for all your online buying and selling needs.
            </p>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            5Gada is best online selling and buying platform
          </CardHeader>
          <CardBody>
            <p>
              for individuals and businesses alike. With its advanced technology
              and seamless user experience, 5Gada offers a wide range of
              products and services. Whether you're searching for electronics,
              fashion, home decor, or more, 5Gada has it all. The platform
              ensures secure transactions and reliable delivery, making it a
              trusted choice for online shoppers. Experience the convenience and
              efficiency of 5Gada for all your online buying and selling needs.
            </p>
          </CardBody>
        </Card>
      </div>
    </>
  );
}

function LandingBanner() {
  //banner bg image
  const bgImages = [
    "images/oromo.jpg",
    "images/trying/faaya.jpg",
    "images/trying/nyaata.png",
    "images/2021_10_irrecha_png.png",
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % bgImages.length);
    }, 5000);
    return () => clearInterval(intervalId);
  }, []);

  // search functionality
  const [search, setSearch] = useState("");

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const handleSubmit = (e) => {
    e.preventDefault();
    searchParams.set("q", search);
    const updatedSearchParams = searchParams.toString();
    const newURL = `/search/?${updatedSearchParams}`;
    navigate(newURL);
  };

  const langType = useSelector((state) => state.store.language);

  return (
    <>
      <div className="landing-banner ">
        <NavBar />
        <div className="banner-bg">
          <LazyLoadImage
            effect="blur"
            className="w-full object-cover object-center"
            width={"100%"}
            height={"100%"}
            src={bgImages[currentImageIndex]}
            placeholderSrc={bgImages[currentImageIndex]}
          />
        </div>
        <div className="interact-user">
          <ul>
            <li>
              <Link className="link" to="/buyer">
                {lang[langType].be_buyer}
              </Link>
            </li>
            <li>
              <Link className="link" to="/seller">
                {lang[langType].be_seller}
              </Link>
            </li>
            <li>
              <Link to="/soon" className="link">
                {lang[langType].get_app}
              </Link>
            </li>
          </ul>
        </div>
        <div className="banner-content">
          <p>
            {lang[langType].homeMessagep}
            <Link to="/about">{lang[langType].explore_more} </Link>
          </p>
          <h1>{lang[langType].homebannerh1}</h1>
          <form onSubmit={handleSubmit} className="search-form">
            <div className="search-container">
              <input
                type="text"
                name="search"
                onChange={handleChange}
                className="search-input"
                placeholder={lang[langType].searchPlaceholder}
              />
              <button type="submit" className="search-button">
                <i className="fas fa-search"></i>
                <span className="mobile_hidden">{lang[langType].search}</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default function HomePage() {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 2000);
  }, []);

  return (
    <>
      <div className="Container">
        <LandingBanner />
      </div>
      <HomeBody />
      {/* <Events /> */}
    </>
  );
}

//coming soon
function ComingSoon() {
  return (
    <div>
      <h1 className="Header-short-line">🛠️ Hello well come to 5gada 🛠️ </h1>
      <h4 className="alert alert-warning">
        5gada is currently under development. If you encounter any errors or
        experience functionality issues, please do not hesitate to contact us.
        Your feedback is valuable as we work towards enhancing and refining the
        application. 🛠️
      </h4>
    </div>
  );
}
