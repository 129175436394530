import axios from "axios";
import React, { useEffect, useState } from "react";
import { APIURL } from "../../asset/host";
import GenericModal from "../../shared/GenericModal";
import { Login } from "../Login_registration";
import { useDispatch, useSelector } from "react-redux";
import { setLoginStatus } from "../../redux/actions";

export default function Like({ productId, noCounter }) {
  const [isLiked, setIsLike] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [likeClick, setLikeClick] = useState(false);
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.store.isLogin);

  useEffect(() => {
    if (isLoggedIn) {
      axios
        .get(
          `${APIURL}/api/products/interaction/liked?productId=${productId}`,
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          setIsLike(res.data.isliked);
        })
        .catch((error) => {
          console.log("");
          if (error.response.status === 401) {
            // setLoggedIn(false);
            console.log("");
          } else {
          }
        });
    }
  }, [productId, isLoggedIn, likeCount]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function likeCountHandler() {
    axios
      .get(`${APIURL}/api/products/like/count/${productId}`)
      .then((res) => {
        if (res.status) {
          setLikeCount(res.data.likeCount);
        }
      })
      .catch((error) => {
        // console.error("Error fetching liked status:", error);
      });
  }
  useEffect(() => {
    likeCountHandler();
  }, [likeCountHandler, productId]);

  const handleLikeClick = (e) => {
    e.stopPropagation();
    setLikeClick(true);

    if (!isLiked) {
      const value = { user_action: "like" };
      axios
        .post(`${APIURL}/api//products/like/${productId}`, value, {
          withCredentials: true,
        })
        .then((res) => {
          setLikeCount(true);
          likeCountHandler();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            dispatch(setLoginStatus(false));
          } else {
          }
          // console.error(err);
        });
    } else {
      const value = { user_action: "dislike" };
      axios
        .post(`${APIURL}/api//products/like/${productId}`, value, {
          withCredentials: true,
        })
        .then((res) => {
          setLikeCount(false);
          likeCountHandler();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            dispatch(setLoginStatus(false));
          } else {
          }
          // console.error(err);
        });
    }
  };
  const [isLoginModalOpen, setLoginIsModalOpen] = useState(false);

  useEffect(() => {
    setLoginIsModalOpen(!isLoggedIn);
  }, [isLoggedIn]);
  const closeLoginModal = () => {
    setLoginIsModalOpen(false);
    // setLoggedIn(true);
  };
  return (
    <>
      <span onClick={handleLikeClick} className="like-product">
        {!isLiked ? (
          <i className="fa-regular fa-heart"></i>
        ) : (
          <i className="fa-solid fa-heart liked"></i>
        )}

        {!noCounter && (
          <div className="like-count">
            {likeCount > 5000000
              ? `${Math.round(likeCount / 1000000)}M`
              : likeCount > 1000
              ? `${Math.round(likeCount / 1000)}k`
              : likeCount}
          </div>
        )}
      </span>
      {!isLoggedIn && likeClick && (
        <>
          <GenericModal
            isOpen={isLoginModalOpen}
            onClose={closeLoginModal}
            children={<Login />}
          />
        </>
      )}
    </>
  );
}
