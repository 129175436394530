import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import { GrGallery } from "react-icons/gr";
import { IoIosInformationCircle } from "react-icons/io";
import { GoHomeFill } from "react-icons/go";
import { useSelector } from "react-redux";
import { ServiceLang, lang, pathLang } from "../asset/language";
import LanguageSelector from "../components/LanguageSelector";

export function NavBar() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const langType = useSelector((state) => state.store.language);
  return (
    <>
      <div className="navBar-header seller-pg">
        <Link to="/" className="logo">
          {" "}
          {isMobile ? (
            <img alt="logo" src="/images/minilogo.png" />
          ) : (
            <img alt="logo" src="/images/logo15.png" />
          )}
        </Link>

        <nav className="right-side">
          <ul className="flex items-center">
            <li>
              <Link className="link flex items-center" to="/">
                <GoHomeFill className="text-2xl" />
                <span className="mobile_hidden">
                  {ServiceLang[langType].home}
                </span>
              </Link>
            </li>
            <li>
              <Link className="link flex items-center" to="/about">
                <IoIosInformationCircle className=" text-2xl" />
                <span className="mobile_hidden">{lang[langType].about}</span>
              </Link>
            </li>

            <li className="help">
              <Link className="link flex  items-center" to="/gallery">
                <GrGallery className=" text-lg" />

                {ServiceLang[langType].gallery}
              </Link>
            </li>
            <li>
              {" "}
              <div className=" top-0 absolute text-xs right-16 sm:static sm:text-sm">
                <LanguageSelector />
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

function SellerLanding() {
  const isLoggedIn = useSelector((state) => state.store.isLogin);
  const username = useSelector((state) => state.store.username);

  return (
    <>
      <div className="SellerLanding">
        <div className="home-left-section">
          <img src="images/sellOnline.png" alt="not found" />
        </div>
        <div className="home-right-section">
          <h1 style={{ color: "#ff4d4d" }}>Well Come To 5G</h1>
          <p>
            Welcome to the 5G online marketing platform, where you have the
            opportunity to freely buy and sell a wide range of goods and
            services. Our platform offers a seamless experience, leveraging the
            power of 5G technology to ensure fast and efficient transactions.
            Whether you are a buyer looking for quality products or a seller
            seeking a vibrant marketplace, our platform provides a convenient
            space for you to connect with a global audience. Join us today and
            unlock endless possibilities in the world of online commerce, all at
            no cost to you.
          </p>
          <button
            onClick={() =>
              !isLoggedIn
                ? (window.location.href = "/user/new")
                : (window.location.href = `/${username}`)
            }
            className="btn btn-primary"
          >
            start Selling now
          </button>
          {!isLoggedIn && (
            <button
              onClick={() => (window.location.href = "/user/login")}
              className="btn btn-secondary"
            >
              Login
            </button>
          )}
        </div>
      </div>
    </>
  );
}
function SellerProm() {
  return (
    <>
      <div className="SellerConf">
        <h1 className="Header-short-line">Why you choose us</h1>
        <Card>
          <CardBody>
            <p>
              5Gada is a remarkable platform that caters to the Oromo community,
              providing a cultural-oriented marketplace for buying and selling a
              wide range of Oromo products. From traditional clothing and shoes
              to exquisite jewelry, this platform offers a diverse selection of
              cultural items that celebrate and preserve the rich heritage of
              the Oromo people. Additionally, 5Gada goes beyond tangible
              products and extends its offerings to include traditional foods
              and raw materials for crafting various items. By facilitating such
              transactions, 5Gada fosters a sense of community and empowers
              Oromo artisans, entrepreneurs, and individuals to showcase and
              share their cultural treasures with a wider audience. Whether you
              are seeking to embrace your Oromo heritage or explore the beauty
              of Oromo culture, 5Gada is the go-to destination for authentic and
              meaningful cultural experiences.
            </p>
          </CardBody>
        </Card>
        <h1 className="Header-short-line">What make us trusted</h1>
        <Card>
          <CardBody>
            <p>
              5Gada is a remarkable platform that caters to the Oromo community,
              providing a cultural-oriented marketplace for buying and selling a
              wide range of Oromo products. From traditional clothing and shoes
              to exquisite jewelry, this platform offers a diverse selection of
              cultural items that celebrate and preserve the rich heritage of
              the Oromo people. Additionally, 5Gada goes beyond tangible
              products and extends its offerings to include traditional foods
              and raw materials for crafting various items. By facilitating such
              transactions, 5Gada fosters a sense of community and empowers
              Oromo artisans, entrepreneurs, and individuals to showcase and
              share their cultural treasures with a wider audience. Whether you
              are seeking to embrace your Oromo heritage or explore the beauty
              of Oromo culture, 5Gada is the go-to destination for authentic and
              meaningful cultural experiences.
            </p>
          </CardBody>
        </Card>
      </div>
    </>
  );
}

export default function Seller() {
  return (
    <>
      <NavBar />
      <SellerLanding />
      <SellerProm />
    </>
  );
}
