import { Link, useLocation } from "react-router-dom";
import {
  FaFacebook,
  FaLinkedin,
  FaRegCopyright,
  FaTelegram,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { lang } from "../asset/language";

function Copyright() {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const updateYear = () => {
      setCurrentYear(new Date().getFullYear());
    };

    const intervalId = setInterval(updateYear, 1000 * 60 * 60); // Update every hour

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures that the effect runs only once on mount

  return (
    <div className="flex items-center p-2">
      <img alt="logo" className=" w-8" src="/images/logodark.png" />
      <FaRegCopyright className=" text-xs" /> 5gada {currentYear}
    </div>
  );
}
function FooterFixed() {
  const [allowed, setAllowed] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Get the pathname and split it by '/'
    const pathSegments = location.pathname.split("/");

    // Check if there is only one level of path
    const isAllowed = pathSegments.length === 2;

    // Update the state based on the URL
    setAllowed(isAllowed);
  }, [location.pathname]);

  const langType = useSelector((state) => state.store.language);
  return (
    <div
      className={` mt-40 flex flex-wrap  p-8 gap-6 pr-10  bg-primary-dark bg-opacity-50 text-white " 
       ${allowed ? "" : "footer"}`}
    >
      <div className="sm:w-60">
        <p className=" text-center">{lang[langType].footerDesc}</p>
      </div>
      <div className="flex-col">
        <h2 className=" pb-0 mb-1"> Contact Us</h2>
        <div>
          <Link to="https://t.me/gada_5">telegram</Link>
        </div>
        <div>
          <Link to="gallery">facebook</Link>
        </div>
        <div>
          <Link to="https://www.linkedin.com/company/5gada/">LinkedIn</Link>
        </div>
        <div>
          <Link to="gallery">Instagram</Link>
        </div>
        <div>
          <Link to="gallery">Tiktok</Link>
        </div>
      </div>
      <div className="flex-col">
        <h2 className=" pb-0 mb-1">Pages</h2>
        <div>
          <Link to="/">home</Link>
        </div>
        <div>
          <Link to="/buyer">buyer</Link>
        </div>
        <div>
          <Link to="/seller">seller</Link>
        </div>
        <div>
          <Link to="gallery">Gallery</Link>
        </div>
      </div>
      <div className="flex-col">
        <h2 className="pb-0 mb-1">Partner</h2>
        <div>
          <Link to="/seller">Promote your service/product</Link>
        </div>
        <div>
          <Link to="/partnership">Partnership request</Link>
        </div>
      </div>
      <div>
        <h2 className="pb-0 mb-1">About</h2>
        <div>
          <Link to="/about">About us</Link>
        </div>

        <div>
          <Link to="/contact-us">Contact Us</Link>
        </div>
        <div>
          <Link to="/partnership/pricing">Pricing</Link>
        </div>
      </div>
      <div>
        <h2 className="pb-0 mb-1">Legal</h2>
        <div>
          <Link>Terms and conditions</Link>
        </div>
        <div>
          <Link>privacy policy</Link>
        </div>
      </div>
      <div>
        <h2 className="pb-0 mb-1">Help and Support </h2>
        <div>
          <a className="ml-3" href="mailto:support@5gada.com">
            support@5gada.com
          </a>
        </div>
      </div>
      <div className="flex-col justify-center items-center ">
        <h2>Stay connected</h2>
        <div className="flex gap-3 text-2xl">
          <Link>
            <FaFacebook className="text-blue-600" />
          </Link>
          <Link to="https://t.me/gada_5">
            <FaTelegram className="text-blue-400" />
          </Link>

          <Link to={"https://www.linkedin.com/company/5gada/"}>
            <FaLinkedin className="text-blue-300" />
          </Link>
          <Link>
            <FaXTwitter className="text-black" />
          </Link>
        </div>
      </div>
    </div>
  );
}
export default function Footer() {
  return (
    <>
      <FooterFixed />
      <Copyright />
    </>
  );
}
