import React from "react";
import oops from "../../asset/error img/oops_404.png";
export default function Page404() {
  return (
    <div className="flex justify-center items-center h-full w-full error-img">
      <img
        src={oops}
        className="m-auto w-full object-fit" 
        alt="page not found "
      />
        
    </div>
  ); 
}
