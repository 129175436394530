import React, { useState } from "react";
import Select from "react-select";

export function ProductCategory({ handleCategorySelect }) {
  const [selectedCategories, setSelectedCategories] = useState([]);

  const handleInputChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedCategories(selectedValues);
    handleCategorySelect(selectedValues);
  };

  const Categories = [
    { value: "callee", label: "callee" },
    { value: "uffata", label: "uffata" }, 
    { value: "faaya", label: "faaya" },
    { value: "cidhaaf", label: "cidhaaf" },
    { value: "meeshalee manaa", label: "meeshalee manaa" },
    { value: "suuralee", label: "suuralee" },
    { value: "maatif", label: "maatif" },
    { value: "couple", label: "couple" },
  ];

  return (
    <Select
      isMulti
      onChange={handleInputChange}
      styles={{ margin: "2px" }}
      name="Category"
      options={Categories}
      className="basic-multi-select mr-2 gap-2"
      classNamePrefix="select"
      value={Categories.filter((category) =>
        selectedCategories.includes(category.value)
      )}
    />
  );
}
