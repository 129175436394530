import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";
import { GrClose } from "react-icons/gr";
import { IoFilterSharp, IoTrashBin } from "react-icons/io5";
import { GoSortAsc, GoSortDesc } from "react-icons/go";
export function SearchFilters({ searchQuery }) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const navigate = useNavigate();

  const handleFilterChange = (e) => {
    const paramName = e.target.name;
    const paramValue = e.target.value;

    if (e.target.type === "checkbox") {
      if (e.target.checked) {
        searchParams.set(paramName, paramValue);
      } else {
        searchParams.delete(paramName);
      }
    } else if (e.target.type === "radio") {
      searchParams.set(paramName, paramValue);
    }
  };
  const applyFirter = () => {
    const updatedSearchParams = searchParams.toString();
    const newURL = `/search/?${updatedSearchParams}`;
    setHideFilter(true);
    navigate(newURL);
  };

  const clearFilter = () => {
    const q = searchParams.get("q");
    searchParams.forEach((value, key) => {
      if (key !== "q") searchParams.delete(key);
    });
    const newURL = `/search/?q=${q}`;
    isMobile && setHideFilter(true);
    isMobile
      ? navigate(newURL) && setHideFilter(true)
      : (window.location.href = newURL);
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [hideFilter, setHideFilter] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {isMobile && hideFilter ? (
        <motion.span
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          onClick={() => {
            setHideFilter(false);
          }}
          className="border-none  align-top  flex items-center cursor-pointer hover:text-primary text-black p-0 pb-0 bg-primary-dark bg-opacity-10 m-0 mb-auto pb-auto"
        >
          <IoFilterSharp className=" p-1 pl-2 text-xl" /> Filter
        </motion.span>
      ) : (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 10 }}
          className=" bg-white p-4 border mb-4 border-gray-300"
        >
          <h2 className="Header-short-line text-xl font-bold mb-4">
            <i className="fas fa-sort mr-2"></i> Filter
          </h2>
          <div className="filter-type mb-4">
            <h5 className="text-lg font-semibold mb-2">Sort By</h5>
            <ul>
              <li className="mb-2  flex items-center">
                <input
                  onChange={handleFilterChange}
                  name="price"
                  checked={searchParams.get("price") === "least" ? true : null}
                  value="least"
                  id="price"
                  type="checkbox"
                  className="mr-2"
                />
                <label htmlFor="price">
                  <GoSortAsc className="text-lg" /> Price
                </label>
              </li>
              <li className="mb-2 flex items-center">
                <input
                  checked={searchParams.get("rating") === "high" ? true : null}
                  onChange={handleFilterChange}
                  name="rating"
                  id="rating"
                  value="high"
                  type="checkbox"
                  className="mr-2"
                />
                <label htmlFor="rating">
                  <GoSortDesc className="text-lg" /> Rating
                </label>
              </li>
              <li className="mb-2  flex items-center">
                <input
                  onChange={handleFilterChange}
                  name="num_view"
                  checked={
                    searchParams.get("num_view") === "high" ? true : null
                  }
                  value="high"
                  id="num_view"
                  type="checkbox"
                  className="mr-2"
                />
                <label htmlFor="num_view">
                  <GoSortDesc className="text-lg" /> View
                </label>
              </li>
            </ul>
          </div>
          <div className="filter-type mb-4">
            <h5 className="text-lg font-semibold mb-2">By Age and Gender</h5>
            <ul>
              <li className="mb-2">
                <input
                  id="men"
                  onChange={handleFilterChange}
                  name="forWhom"
                  checked={searchParams.get("forWhom") === "men" ? true : null}
                  value="men"
                  type="radio"
                  className="mr-2"
                />
                <label htmlFor="male">Men</label>
              </li>
              <li className="mb-2">
                <input
                  id="women"
                  checked={
                    searchParams.get("forWhom") === "women" ? true : null
                  }
                  onChange={handleFilterChange}
                  value="women"
                  name="forWhom"
                  type="radio"
                  className="mr-2"
                />
                <label htmlFor="Female">Women</label>
              </li>

              <li className="mb-2">
                <input
                  checked={searchParams.get("forWhom") === "kid" ? true : null}
                  id="kid"
                  value="kid"
                  onChange={handleFilterChange}
                  name="forWhom"
                  type="radio"
                  className="mr-2"
                />
                <label htmlFor="kid">Kids</label>
              </li>
              <li className="mb-2">
                <input
                  id="all"
                  checked={searchParams.get("forWhom") === "all" ? true : null}
                  onChange={handleFilterChange}
                  value="all"
                  name="forWhom"
                  type="radio"
                  className="mr-2"
                />
                <label htmlFor="all">For all / general</label>
              </li>
            </ul>
          </div>
          <div className="filter-type mb-4">
            <h5 className="text-lg font-semibold mb-2">Condition</h5>
            <ul>
              <li className="mb-2">
                <input
                  onChange={handleFilterChange}
                  name="condition"
                  checked={
                    searchParams.get("condition") === "new" ? true : null
                  }
                  value="new"
                  id="new"
                  type="radio"
                  className="mr-2"
                />
                <label htmlFor="new">New</label>
              </li>
              <li className="mb-2">
                <input
                  checked={
                    searchParams.get("condition") === "used" ? true : null
                  }
                  onChange={handleFilterChange}
                  name="condition"
                  id="used"
                  value="used"
                  type="radio"
                  className="mr-2"
                />
                <label htmlFor="used">Used</label>
              </li>
            </ul>
            <button onClick={applyFirter} className="btn btn-secondary">
              apply filter
            </button>
            <span>
              <button
                onClick={clearFilter}
                className="bg-gray-400 flex items-center hover:bg-gray-500 btn text-white py-2 px-4 rounded-md mb-4"
              >
                <IoTrashBin />
                Clear
                {isMobile && (
                  <>
                    and
                    <GrClose /> close
                  </>
                )}
              </button>
            </span>
          </div>
        </motion.div>
      )}
    </>
  );
}
