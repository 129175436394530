import React, { useEffect, useState } from "react";
import { APIURL } from "../../asset/host";
import axios from "axios";
import GenericModal, {
  GenericLittleLoadingModal,
} from "../../shared/GenericModal";
import Loading from "../Loading";
import { useNavigate, useParams } from "react-router-dom";
import { Card } from "reactstrap";

export default function SellerCompany({ username }) {
  return (
    <div>
      <BusinessRegistrationForm />
    </div>
  );
}

function BusinessRegistrationForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msg, setMsg] = useState("");
  const [companyData, setCompanyData] = useState({
    name: "",
    location: "",
    telegram: "",
    phoneNumber: "",
    email: "",
    website: "",
    description: "",
    License: null,
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "License") {
      // Handle file input change
      setCompanyData((prevData) => ({
        ...prevData,
        License: files[0],
      }));
    } else {
      // Handle other input changes
      setCompanyData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    const formData = new FormData();
    formData.append("name", companyData.name);
    formData.append("location", companyData.location);
    formData.append("telegram", companyData.telegram);
    formData.append("phoneNumber", companyData.phoneNumber);
    formData.append("email", companyData.email);
    formData.append("website", companyData.website);
    formData.append("description", companyData.description);
    formData.append("License", companyData.License);

    axios
      .post(`${APIURL}/api/user/addCompany`, formData, {
        withCredentials: true,
      })
      .then((res) => {
        setLoading(false);
        if (res.status === 201) {
          setSuccess(true);
          setMsg(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        if (err.response.status === 401 || err.response.status === 403) {
          setMsg(
            err.response.data.message +
              " You are going to redirected to login page in 5 sec"
          );
          setTimeout(() => {
            navigate("/login");
          }, 5000);
        } else {
          setMsg(err.response.data.message);
        }
      });
  };
  const [openLoading, setOpenLoading] = useState(false);
  useEffect(() => {
    setOpenLoading(loading);
  }, [loading]);
  const { username } = useParams();
  return (
    <div>
      <GenericLittleLoadingModal isOpen={openLoading} />
      <h2 className="Header-short-line">Register Your Business</h2>
      <>
        {msg ? (
          <div className="card-non-hover">
            <div
              className={success ? "alert alert-success" : "alert alert-danger"}
            >
              {msg}
              {setTimeout(() => navigate(`/${username}`), 3000)}
            </div>
          </div>
        ) : (
          <form
            className="registration-form"
            method="POST"
            encType="multipart/form-data"
            onSubmit={handleSubmit}
          >
            <label>
              Company Name / business name*
              <input
                type="text"
                name="name"
                placeholder="Enter name of the company / business e.g tola design"
                value={companyData.name}
                onChange={handleChange}
                required
              />
            </label>
            <br />
            <label>
              Location:*
              <input
                type="text"
                name="location"
                placeholder="Enter location of your company e.g finfinnee mujjaa"
                value={companyData.location}
                onChange={handleChange}
                required
              />
            </label>

            <br />
            <label>
              Phone Number:*
              <input
                type="tel"
                placeholder="Enter phone number eg 09xxxxxxx"
                name="phoneNumber"
                value={companyData.phoneNumber}
                onChange={handleChange}
                required
              />
            </label>
            <br />
            <label>
              Telegram username:(optional)
              <input
                type="url"
                placeholder="enter your telegram username link e.g. https://t.me/kiyualv"
                name="telegram"
                value={companyData.telegram}
                onChange={handleChange}
              />
            </label>
            <br />
            {/* <label>
              Phone Number:*
              <input
                type="tel"
                placeholder="Enter phone number eg 09xxxxxxx"
                name="phoneNumber"
                value={companyData.phoneNumber}
                onChange={handleChange}
                required
              />
            </label> */}
            <br />
            <label>
              Email: (optional)
              <input
                type="email"
                name="email"
                placeholder="Enter email here | youreus@domain.com"
                value={companyData.email}
                onChange={handleChange}
                // required
              />
            </label>
            <br />
            <label>
              Website:(optional)
              <input
                placeholder="Enter your company website if any (optional)"
                type="url"
                name="website"
                value={companyData.website}
                onChange={handleChange}
              />
            </label>
            <br />
            <label>
              Description:
              <textarea
                placeholder="Add general description about your company if any...  e.g tola design is one of best ...."
                name="description"
                value={companyData.description}
                onChange={handleChange}
              />
            </label>
            <br />

            <label>
              Business License Image: (Optional)
              <input
                type="file"
                name="License"
                accept="image/*"
                onChange={handleChange}
              />
            </label>
            <br />
            <button className="btn btn-primary" type="submit">
              Create
            </button>
          </form>
        )}
      </>
    </div>
  );
}
