import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { APIURL } from "../../asset/host";
import axios from "axios";
import { LoadingCardVert, PreLoading } from "../../shared/LoadingCard";
import InfiniteScroll from "react-infinite-scroll-component";
import Loading from "../Loading";
import UserAction from "./UserAction";
import { Card, CardImg, CardTitle } from "reactstrap";
import { RateDispayer } from "./RatingsComponent";
import { TimeAgo } from "../../shared/TimeAgo";


export default function MyFavorite() {
  const [products, setProduct] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const cards = document.querySelectorAll(".fadeInAnimation");
    cards.forEach((card, index) => {
      setTimeout(() => {
        card.classList.add("visible");
      }, 50 * index);
    });
  }, [products]);

  const fetchMoreData = () => {
    if (hasMore) {
      setPage((prevPage) => prevPage + 1);
    } else {
      setLoading(false);
    }
  };
  useEffect(() => {
    var apiUrl = `${APIURL}/api/products/user/myFavorites/?page=${page}`;
    axios
      .get(apiUrl, { withCredentials: true })
      .then((response) => {
        setProduct((prevProducts) => [...prevProducts, ...response.data]);
        setLoading(false);
        const newProducts = response.data;
        if (newProducts.length === 0 || newProducts.length < 21) {
          setHasMore(false);
          setLoading(false);
        }
      })

      .catch((err) => {
        console.log(err);
        setLoading(false);
        setError(true);
      });
  }, [page]);

  const handleProductDetail = (product) => {
    navigate(`/buyer/product/${product.id}`);
  };
  // NEED TO BE EDITED
  const userId = null;
  return (
    <div>
      {loading ? (
        <div className="Card_list product-list">
          <PreLoading n={6} children={<LoadingCardVert />} />
        </div>
      ) : !error ? (
        products.length > 0 ? (
          <InfiniteScroll
            dataLength={products?.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<Loading />}
          >
            <div className="Card_list product-list">
              {products.map((product, key) => (
                <Card
                  onClick={() => handleProductDetail(product)}
                  key={key}
                  className="fadeInAnimation"
                >
                  <UserAction
                    product={product}
                    userId={userId}
                    productId={product.id}
                  />
                  <CardImg
                    src={`${APIURL}/api/getImage/` + product.filename}
                    alt="not found"
                  ></CardImg>

                  <CardTitle>
                    {product.title} <br />
                    <b>{product.price} ETB</b> <br />
                    <RateDispayer productId={product.id} /> <br />
                    <span className="muted">{product.num_views} views</span>
                    <br />
                    <span className="muted">{TimeAgo(product.created_at)}</span>
                    <br />
                    {product.condition}
                  </CardTitle>
                </Card>
              ))}
            </div>
          </InfiniteScroll>
        ) : (
          <h1>NO Product found</h1>
        )
      ) : (
        <div className="alert alert-danger">
          Error happend while loading data
        </div>
      )}
    </div>
  );
}
