import axios from "axios";
import React, { useEffect, useState } from "react";
import { APIURL } from "../../asset/host";
import {
  LoadingCardImg,
  LoadingCardVert,
  PreLoading,
} from "../../shared/LoadingCard";
import InfiniteScroll from "react-infinite-scroll-component";
import Loading from "../Loading";
import MyImageModal from "./Modal";
import GalleryHeader from "./galleryHeader";
import SearchFilter from "./searchFilter";
import { useLocation } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export default function PhotoGallery() {
  const [photos, setPhotos] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(null);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const updatedSearchParams = searchParams.toString();
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${APIURL}/api/gallery?${updatedSearchParams}&page=${pageNumber}`)
      .then((res) => {
        const newPhotos = res.data.map((file) => ({
          src: `${APIURL}/api/getImage/${file.filename}`,
          width: 248,
          height: 320,
          caption: file.title,
          id: file.id,
          name: file.name,
        }));

        setPhotos(newPhotos);
        if (res.data.length === 0 || res.data.length < 40) {
          setHasMore(false);
        } else {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
        setLoading(false);
      })
      .catch((err) => {
        setError(err.response.data.message);
        setLoading(false);
      });
  }, [updatedSearchParams, pageNumber]);

  const fetchPhotos = () => {
    axios
      .get(`${APIURL}/api/gallery?${updatedSearchParams}&page=${pageNumber}`)
      .then((res) => {
        const newPhotos = res.data.map((file) => ({
          src: `${APIURL}/api/getImage/${file.filename}`,
          width: 248,
          height: 320,
          caption: file.title,
          id: file.id,
          name: file.name,
        }));

        setPhotos((prevPhotos) => [...prevPhotos, ...newPhotos]);
        if (res.data.length === 0 || res.data.length < 40) {
          setHasMore(false);
        } else {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
        setLoading(false);
      })
      .catch((err) => {
        setError(err.response.data.message);
        setLoading(false);
      });
  };

  const viewPhotoIncrease = (id) => {
    const data = {
      view: true,
    };
    axios
      .put(`${APIURL}/api/products/view/${id}`, data)
      .then((response) => {})
      .catch((error) => {
        console.error("Error updating view count:", error);
      });
  };

  useEffect(() => {
    if (currentIndex !== null) {
      viewPhotoIncrease(photos[currentIndex].id);
    }
  }, [currentIndex]);

  const handleImageClick = (index) => {
    setCurrentIndex(index);
    setModalOpen(true);
  };
  const nextImage = (index) => {
    setCurrentIndex(index + 1);
  };
  const prevImage = (index) => {
    setCurrentIndex(index - 1);
  };
  const closeModal = () => {
    setCurrentIndex(null);
    setModalOpen(false);
  };
  return (
    <div>
      <GalleryHeader />
      <SearchFilter />
      <>
        {error !== null ? (
          <div className="alert alert-danger">{error}</div>
        ) : !loading ? (
          <>
            <InfiniteScroll
              dataLength={photos.length}
              next={fetchPhotos}
              hasMore={hasMore}
              loader={<Loading />}
              endMessage={<p>No more photos to load</p>}
            >
              <div className="grid grid-cols-4 md:grid-cols-8 gap-1">
                {photos?.map((photo, index) => (
                  <div className="relative">
                    <LazyLoadImage
                      className="w-full  object-cover aspect-square"
                      alt={photo.caption}
                      key={photo.src}
                      effect="blur"
                      onClick={() => handleImageClick(index)}
                      src={photo.src}
                      height={"100%"}
                      width={"100%"}
                      placeholderSrc={photo.src}
                    />
                  </div>
                ))}
              </div>
            </InfiniteScroll>
          </>
        ) : (
          <div className="grid grid-cols-4 md:grid-cols-8 gap-1">
            <PreLoading n={40} children={<LoadingCardImg />} />
          </div>
        )}
        {modalOpen && (
          <MyImageModal
            src={photos[currentIndex]?.src}
            alt={photos[currentIndex].name}
            next={() => nextImage(currentIndex)}
            prev={() => prevImage(currentIndex)}
            closeModal={closeModal}
            length={photos.length}
            index={currentIndex}
          />
        )}
      </>
    </div>
  );
}
