import { Card, CardImg } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { APIURL } from "../asset/host";
import { PreLoading } from "../shared/LoadingCard";
import { BuyerNav } from "../../src/components/BuyerNav";
import { useSelector } from "react-redux";
import { TimeAgo } from "../shared/TimeAgo";
import { Product } from "../components/product/Product";

export function Childrens() {
  useEffect(() => {
    document.title = "5gada products for kid";
  }, []);
  const isLoggedIn = useSelector((state) => state.store.isLogin);
  return (
    <>
      <Product login={isLoggedIn} filter={{ forWhom: "kid" }} />
    </>
  );
}
export function Men() {
  useEffect(() => {
    document.title = "5gada products for men";
  }, []);

  return (
    <>
      <Product filter={{ forWhom: "men" }} />
    </>
  );
}
export function Women() {
  useEffect(() => {
    document.title = "5gada products for women";
  }, []);

  return (
    <>
      <Product filter={{ forWhom: "women" }} />
    </>
  );
}
export function New() {
  useEffect(() => {
    document.title = "5gada latest products";
  }, []);

  return (
    <>
      {/* <h3 className="Header-short-line">Latest products</h3> */}
      <Product filter={"latest"} />
    </>
  );
}
// category pg
export function Jewelries() {
  useEffect(() => {
    document.title = "5gada Jewelries";
  }, []);

  return (
    <>
      <Product filter={{ category: "Jewelries" }} />
    </>
  );
}
export function MeeshaaAadaa() {
  useEffect(() => {
    document.title = "5gada Meeshaale aadaa";
  }, []);
  return (
    <>
      <Product filter={{ category: "Meeshale-aadaa" }} />
    </>
  );
}

function FeaturedProduct() {
  useEffect(() => {
    document.title = "5gada featured products ";
  }, []);

  const navigate = useNavigate();
  const [products, setProduct] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  useEffect(() => {
    // console.log(products);
    const cards = document.querySelectorAll(".fadeInAnimation");
    cards.forEach((card, index) => {
      setTimeout(() => {
        card.classList.add("visible");
      }, 50 * index);
    });
  }, [products]);
  const apiUrl = `${APIURL}/api/products/featured`;
  // for next version
  // const apiUrl = `${APIURL}/api/products?featured=true&sort=latest`;
  useEffect(() => {
    axios
      .get(apiUrl)
      .then((response) => {
        setProduct(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setError(true);
      });
  }, [apiUrl]);

  const handleProductDetail = (product) => {
    navigate(`/buyer/product/${product.id}`);
  };

  // let products = ProductList.filter((prod) => prod.featured === "true");
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }

    const truncatedText = text.slice(0, maxLength);
    const lastSpaceIndex = truncatedText.lastIndexOf(" ");

    if (lastSpaceIndex === -1) {
      return `${truncatedText} ...`;
    }

    return `${truncatedText.slice(0, lastSpaceIndex)} ...`;
  };
const langType = useSelector((state) => state.store.language);
  return (
    <>
      <div>
        {loading ? (
          <div className="Card_list product-list">
            <PreLoading n={6} />
          </div>
        ) : !error ? (
          <div className="Card_list product-list">
            {" "}
            {products.length > 0 ? (
              products?.map((prod, key) => (
                <Card
                  onClick={() => handleProductDetail(prod)}
                  key={key}
                  className="fadeInAnimation"
                >
                  <CardImg
                    src={`${APIURL}/api/getImage/` + prod.filename}
                    alt="not found"
                  ></CardImg>
                  <div className="detailed-info">
                    <p>{truncateText(prod.description, 70)}</p>
                    <p className="muted">{TimeAgo(prod.created_at, langType)}</p>
                  </div>
                </Card>
              ))
            ) : (
              <p>No featured product</p>
            )}
          </div>
        ) : (
          <div className="alert alert-danger">
            Error happened while loading data
          </div>
        )}
      </div>
    </>
  );
}

export default function Buyer() {
  const [changeCart, setCartChange] = useState(true);
  const [username, setUsername] = useState("");

  useEffect(() => {
    axios
      .get(`${APIURL}/api/user/me`, { withCredentials: true })
      .then((res) => {
        setUsername(res.data[0].username);
      })
      .catch((err) => {});
  }, []);
  return (
    <>
      <BuyerNav updateCart={() => setCartChange(true)} />
      <FeaturedProduct />
    </>
  );
}

export function BuyerPage({ children }) {
  return (
    <>
      <BuyerNav />
      
      {children}
    </>
  );
}
