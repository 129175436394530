import { useSelector } from "react-redux";
import { lang } from "../asset/language";

export const TimeAgo = (timestamp, lang_type) => {
  // const lang_type = "om";
  const currentDate = new Date();
  const createdAt = new Date(timestamp);
  const timeDifference = currentDate - createdAt;

  // const lang_type = useSelector((state) => state.store.language);
  // Time units in milliseconds
  const oneSecond = 1000;
  const oneMinute = oneSecond * 60;
  const oneHour = oneMinute * 60;
  const oneDay = oneHour * 24;
  const oneMonth = oneDay * 30; // Approximate value, not precise for all months
  const oneYear = oneMonth * 12; // Approximate value, not precise for all years

  if (lang_type === "om") {
    if (timeDifference < oneSecond) {
      return lang.en.just_now;
    } else if (timeDifference < oneMinute) {
      const seconds = Math.floor(timeDifference / oneSecond);
      return `${lang.om.second} ${seconds}  dura`;
    } else if (timeDifference < oneHour) {
      const minutes = Math.floor(timeDifference / oneMinute);
      return `${lang.om.minute} ${minutes} dura`;
    } else if (timeDifference < oneDay) {
      const hours = Math.floor(timeDifference / oneHour);
      return `${lang.om.hour} ${hours} dura`;
    } else if (timeDifference < oneMonth) {
      const days = Math.floor(timeDifference / oneDay);
      return `${lang.om.day} ${days} dura`;
    } else if (timeDifference < oneYear) {
      const months = Math.floor(timeDifference / oneMonth);
      return `${lang.om.month} ${months} dura`;
    } else {
      const years = Math.floor(timeDifference / oneYear);
      return `${lang.om.year} ${years} dura`;
    }
  } else {
    if (timeDifference < oneSecond) {
      return lang.en.just_now;
    } else if (timeDifference < oneMinute) {
      const seconds = Math.floor(timeDifference / oneSecond);
      return `${seconds} second${seconds !== 1 ? "s" : ""} ago`;
    } else if (timeDifference < oneHour) {
      const minutes = Math.floor(timeDifference / oneMinute);
      return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
    } else if (timeDifference < oneDay) {
      const hours = Math.floor(timeDifference / oneHour);
      return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    } else if (timeDifference < oneMonth) {
      const days = Math.floor(timeDifference / oneDay);
      return `${days} day${days !== 1 ? "s" : ""} ago`;
    } else if (timeDifference < oneYear) {
      const months = Math.floor(timeDifference / oneMonth);
      return `${months} month${months !== 1 ? "s" : ""} ago`;
    } else {
      const years = Math.floor(timeDifference / oneYear);
      return `${years} year${years !== 1 ? "s" : ""} ago`;
    }
  }
};
