import axios from "axios";
import React, { useEffect, useState } from "react";
import { APIURL } from "../../asset/host";
import { Link, useParams } from "react-router-dom";
import { CompanyProduct, UserProfileDetail } from "./UserProfile";
import UserContact from "./UserContact";
import { NavBar } from "../../pages/SellerComp";
import Page404 from "../error/Page404";

export default function OtherUserProfile() {
  const { username } = useParams();
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    axios
      .get(`${APIURL}/api/users/get/${username}`)
      .then((res) => {
        // console.log(res.data);
        setUserData(res.data);
      })
      .catch((err) => {
        console.log(err.response.data.error);
        if (err.response.status === 404) {
          setError(err.response.data.error);
        }
      });
  }, [username]);
  const handleBack = () => {
    window.history.back();
  };

  return (
    <div>
      <NavBar />

      {error !== null ? (
        <div className="flex-col justify-center text-center items-center">
          <div className=" w-96 mt-8 sm:mt-14 flex-col justify-center m-auto overflow-hidden h-96">
            <Page404 />
          </div>
          <div className=" text-3xl  font-bold">Oops! sorry this page not found</div>
          <Link to="/" className="btn no-underline bg-primary text-white">
            Go to home
          </Link>
          <button className="btn bg-gray-400 text-white" onClick={handleBack}>
            back
          </button>
        </div>
      ) : (
        <>
          <UserProfileDetail notMe={true} userInfo={userData} />
          <UserContact userInfo={userData} />
          <div className=" bg-gray-300 mx-2 rounded text-lg px-2 flex">
            Posts
          </div>
          <CompanyProduct filter={userData.company} />
        </>
      )}
    </div>
  );
}
