import React, { useEffect, useState } from "react";
import { APIURL } from "../../../asset/host";
import axios from "axios";
import OverLay from "../../overLay";
import { Link } from "react-router-dom";
import GenericModal from "../../../shared/GenericModal";
import { Login } from "../../Login_registration";
import { useDispatch, useSelector } from "react-redux";

import { setCarts } from "../../../redux/actions";
import { lang } from "../../../asset/language";

export default function Cart({ product, productId, placeholder }) {
  const [cartModal, setCartModal] = useState(false); // Initialize with false
  useEffect(() => {
    if (!placeholder) {
      const cartText = document.getElementById(productId);
      const cartIcon = document.querySelector(`.cart-${productId}-icon`);

      const handleMouseEnter = () => {
        cartText.classList.add("display-cart-text");
      };

      const handleMouseOut = () => {
        cartText.classList.remove("display-cart-text");
      };

      cartIcon.addEventListener("mouseenter", handleMouseEnter);
      cartIcon.addEventListener("mouseout", handleMouseOut);

      return () => {
        cartIcon.removeEventListener("mouseenter", handleMouseEnter);
        cartIcon.removeEventListener("mouseout", handleMouseOut);
      };
    }
  }, [productId]);

  const handleLikeClick = (e) => {
    e.stopPropagation();
    setCartModal(true); // Open the modal when the cart icon is clicked
  };
  const langType = useSelector((state) => state.store.language);
  return (
    <>
      {cartModal && (
        <CartModal product={product} closeModal={() => setCartModal(false)} />
      )}

      {placeholder ? (
        <span
          className="link-primary cart-placeholder"
          onClick={handleLikeClick}
        >
          {lang[langType].addToCart}
        </span>
      ) : (
        <div className="cart-element">
          <span className="cart-text" id={productId}>
            {lang[langType].addToCart}
          </span>
          <i
            onClick={handleLikeClick}
            className={`fa fa-shopping-cart cart-${productId}-icon`}
          ></i>
        </div>
      )}
    </>
  );
}

function CartModal({ product, closeModal }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cartAdded, setCartAdded] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(true);
  const dispatch = useDispatch();
  const langType = useSelector((state) => state.store.language);

  function openModal() {
    setIsModalOpen(true);
  }

  useEffect(() => {
    if (product) {
      openModal();
    }
  }, [product]);

  const handleAddCart = () => {
    const value = {
      userId: 420,
    };
    axios
      .post(`${APIURL}/api/products/carts/${product.id}`, value, {
        withCredentials: true,
      })
      .then((res) => {
        setCartAdded(true);
      })
      .catch((err) => {
        //  console.log(err);
        if (err.response.status === 401) {
          setLoggedIn(false);
        }
      });

    setTimeout(() => {
      setIsModalOpen(false);

      axios
        .get(`${APIURL}/api/products/user/carts`, {
          withCredentials: true,
        })
        .then((res) => {
          dispatch(setCarts(res.data.Carts.nums));
        })
        .catch((err) => {
          if (err.response) {
            console.error(
              "Server responded with an error:",
              err.response.status,
              err.response.data
            );
          } else if (err.request) {
            console.error("No response received:", err.request);
          } else {
            console.error("Error setting up the request:", err.message);
          }
        });
    }, 2000);
  };

  const [isLoginModalOpen, setLoginIsModalOpen] = useState(false);

  useEffect(() => {
    setLoginIsModalOpen(!isLoggedIn);
    if (!isLoggedIn) {
      setIsModalOpen(false);
    }
  }, [isLoggedIn]);
  const closeLoginModal = () => {
    setLoginIsModalOpen(false);
  };
  return (
    <>
      {isModalOpen && (
        <GenericModal isOpen={isModalOpen} onClose={closeModal}>
          <div>
            <h2> {lang[langType].addToCart}</h2>
            {product.title}
            <br />

            {!cartAdded ? (
              <button onClick={handleAddCart} className="btn btn-primary">
                {lang[langType].addToCart}
              </button>
            ) : (
              <span className="btn btn-secondary">
                Added successfully <i class="fa-solid fa-check fa-beat"></i>
              </span>
            )}
          </div>
        </GenericModal>
      )}

      <>
        {!isLoggedIn ? (
          <GenericModal
            isOpen={isLoginModalOpen}
            onClose={closeLoginModal}
            children={<Login />}
          />
        ) : (
          <></>
        )}
      </>
    </>
  );
}
