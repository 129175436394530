import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Card, CardFooter, CardImg } from "reactstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaLocationDot } from "react-icons/fa6";
import axios from "axios";
import Loading from "../Loading";
import { APIURL } from "../../asset/host";
import UserAction from "../product-interaction/UserAction";
import { ProductRating } from "../product-interaction/RatingsComponent";
import { LoadingCardVert } from "../../shared/LoadingCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneSquare } from "@fortawesome/free-solid-svg-icons";
import { FaFacebook, FaTelegram } from "react-icons/fa";
import { useSelector } from "react-redux";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { actionLang, lang } from "../../asset/language";

export default function ProductDetail() {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [viewCountIncremented, setViewCountIncremented] = useState(false);
  const [product, setProduct] = useState({});
  const [companyInfo, setCompanyInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const { id } = useParams();

  const apiUrl = `${APIURL}/api/products/${id}`;

  useEffect(() => {
    axios
      .get(apiUrl)
      .then((response) => {
        setProduct(response.data[0]);
        setLoading(false);
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        console.error("Error:", error.message);
        setLoading(false);
        setError(true);
      });
  }, [apiUrl]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    accessibility: true,
    swipeToSlide: true,
    touchMove: true,
    autoplay: isVideoPlaying ? false : true,
    autoplaySpeed: 3000, // Set the speed to 0 when video is playing
  };
  useEffect(() => {
    if (product && !viewCountIncremented) {
      setViewCountIncremented(true);
      const data = {
        view: true,
      };
      axios
        .put(`${APIURL}/api/products/view/${id}`, data)
        .then((response) => {
          // Handle success if needed
          setViewCountIncremented(true); // Set the flag to true
        })
        .catch((error) => {
          console.error("Error updating view count:", error);
        });
    }
    setViewCountIncremented(true);
  }, [product, viewCountIncremented]);

  useEffect(() => {
    const productDisplay = document.getElementById(`${id}Product`);
    if (productDisplay) {
      productDisplay.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, []);

  useEffect(() => {
    axios
      .get(`${APIURL}/api/company/${product.company_id}`)
      .then((response) => {
        setCompanyInfo(response.data);
      })
      .catch((error) => {
        console.error("Error updating view count:", error);
      });
  }, [product]);

  const langType = useSelector((state) => state.store.language);
  return (
    <div>
      <div id={id + "Product"} className="Product-Detail mb-20">
        {loading ? (
          <LoadingCardVert />
        ) : !error ? (
          <>
            {" "}
            <Card>
              <Slider {...settings}>
                {product && product.Images ? (
                  product.Images.map((image, index) => (
                    <div key={index}>
                      {image ? (
                        <LazyLoadImage
                          className="card-img h-full w-full object-cover object-center"
                          alt={product.title}
                          effect="blur"
                          delayTime={3000}
                          src={`${APIURL}/api/getImage/` + image}
                          height={"50%"}
                          width={"100%"}
                          placeholderSrc={`${APIURL}/api/getImage/` + image}
                        />
                      ) : (
                        <Loading />
                      )}
                    </div>
                  ))
                ) : (
                  <Loading />
                )}
                {/* <video src="" autoPlay="true" /> */}
              </Slider>
              <UserAction product={product} productId={product.id} />
              <br /> <br /> <br />
              <CardFooter>
                {product?.title}
                <br /> {lang[langType].price}:{product?.price} ETB
                <br />
                {product?.product_condition}
                <br />
                <div className="muted flex items-center">
                  <FaLocationDot /> {product?.location}
                </div>
                <span className="muted">{product.num_views} views</span> <br />
                <ProductRating productId={id} />
              </CardFooter>
              <div>
                <h2> {actionLang[langType].prodDesc}</h2>
                <p>{product?.description}</p>
              </div>
            </Card>
            <div className="seler-detail card-non-hover">
              <div
                onClick={() =>
                  (window.location.href = `/${companyInfo.username}`)
                }
                className="profile-pic seler-pic cursor-pointer"
              >
                <CardImg src="/images/user-icon-placeholder-1.png" />
              </div>
              <div className="user-information mt-2">
                <div>
                  {companyInfo.name}
                  <Link
                    className="ml-1 no-underline bg-primary-light text-xs rounded-md p-0.5 bg-opacity-45 hover:bg-opacity-70 "
                    to={`/${companyInfo.username}`}
                  >
                    {actionLang[langType].viewProf}
                  </Link>
                </div>
                <table>
                  <tbody>
                    <tr>
                      <td>{lang[langType].owner}:</td>
                      <td style={{ textTransform: "capitalize" }}>
                        {companyInfo.fullname}
                      </td>
                    </tr>
                    <tr className=" items-center">
                      <td>{lang[langType].JoinedDate}:</td>
                      <td className="muted">
                        {" "}
                        {new Date(companyInfo.joinedDate).toLocaleString(
                          "en-US",
                          {
                            month: "long",
                            year: "numeric",
                          }
                        )}
                      </td>
                    </tr>
                    {/* <tr>
                      <td>verified:</td>
                      <td>
                        <i
                          style={{ color: "rgb(36, 144, 245)" }}
                          class="fa-solid fa-circle-check"
                        ></i>
                      </td>
                    </tr> */}
                    {/* <tr>
                      
                      <td>trust rate:</td>
                      <td>
                       
                        {companyInfo.avg_rating} ⭐
                        <span>{companyInfo.RateCount} reviews</span>
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>

              <div className="contact-user">
                <ul>
                  <li>
                    <Link className="link-primary flex items-center">
                      <FaFacebook className="mr-0.5 text-xl text-blue-700" />
                      Facebook
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={companyInfo.telegram}
                      className="link-primary flex items-center"
                    >
                      <FaTelegram className="mr-0.5 text-xl text-blue-400" />
                      telegram
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`tel:${companyInfo.phoneNumber}`}
                      className="link-primary flex items-center"
                    >
                      <FontAwesomeIcon
                        icon={faPhoneSquare}
                        className="mr-0.5 text-xl text-red-600"
                      />
                      {actionLang[langType].call}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </>
        ) : (
          <div className="alert alert-danger">Error while fetching data</div>
        )}
      </div>
    </div>
  );
}
