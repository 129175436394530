import { useState, forwardRef, useImperativeHandle, useEffect } from 'react'
import { CardImg } from 'reactstrap'

function ProductBigger (props, ref) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  function unset (e) {
    e.stopPropagation()
  }

  const [editable, setEditable] = useState([props.body])
  const [edit, setEdit] = useState(false)
  async function openModal () {
    setIsModalOpen(true)
    setEditable(props.body)
    console.log(props.body)

    const convertedFile = await fetch(props.body)
      .then(response => response.blob())
      .then(
        blob => new File([blob], props.body.name, { type: props.body.type })
      )

    console.log(convertedFile)
  }

  const handleUpdate = event => {
    const files = event.target.files

    if (files.length > 0) {
      const file = files[0]

      if (file.type === 'image/') {
        console.log('is image')
      }
      setEdit(true)
      setEditable(file)
    }
  }
  function closeModal () {
    setIsModalOpen(false)
    if (edit) {
      props.replaceProduct(editable)
    }
    setEdit(false)
    setEditable([])
  }

  useEffect(() => {}, [])

  useImperativeHandle(ref, () => ({
    openModal: () => openModal(),
    closeModal: () => closeModal()
  }))

  return (
    <>
      {isModalOpen && (
        <>
          <CardImg src={edit ? URL.createObjectURL(editable) : editable} />

          <button className='btn btn-primary'>
            <label htmlFor='updateImage'>Replace the image </label>
          </button>

          <input
            style={{ display: 'none' }}
            type='file'
            id='updateImage'
            onChange={handleUpdate}
            className='update-me'
          />
        </>
      )}
    </>
  )
}

export default forwardRef(ProductBigger)
