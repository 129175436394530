import React, { useEffect, useState } from "react";
import OverLay from "../../overLay";
import axios from "axios";
import { APIURL } from "../../../asset/host";
import { SearchDisplayer } from "../../search/Search";
import Loading from "../../Loading";
import GenericModal from "../../../shared/GenericModal";
import { Login } from "../../Login_registration";
import { useSelector } from "react-redux";

export default function CartDisplayer({ cartChanged, closeCartDisp }) {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [Deleted, setDeleted] = useState(false);
  const [totalPrice, setTotalPrice] = useState(null);

  const [isLoggedIn, setLoggedIn] = useState(true);
  useEffect(() => {
    //need to be edited
    if (Deleted) {
      axios
        .get(`${APIURL}/api/products/user/carts?all=true`, {
          withCredentials: true,
        })
        .then((res) => {
          setProducts(res.data.Carts);
          setLoading(false);
          setDeleted(false);
          cartChanged();
        })
        .catch((err) => {
          //add how to handle error status 401, 404, 403 and 402 here
          setLoading(false);
          setError(true);
        });
    }
    axios
      .get(`${APIURL}/api/products/user/carts?all=true`, {
        withCredentials: true,
      })
      .then((res) => {
        setProducts(res.data.Carts);
        let sum = 0;
        res.data.Carts.forEach((element) => {
          sum += parseFloat(element.price);
        });
        const totalPrice = sum.toFixed(2);
        setTotalPrice(totalPrice);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.status === 401) {
          setLoggedIn(false);
        }
        setError(true);
      });
  }, [Deleted]);

  const handleRemoveCart = (cart) => {
    axios
      .delete(
        `${APIURL}/api/products/user/carts?userId=42&cartId=${cart.cartId}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setDeleted(true);
        } else {
        }
      })
      .catch((err) => {});
  };

  const [isLoginModalOpen, setLoginIsModalOpen] = useState(false);

  useEffect(() => {
    setLoginIsModalOpen(!isLoggedIn);
  }, [isLoggedIn]);
  const closeLoginModal = () => {
    setLoginIsModalOpen(false);
  };
  const langType = useSelector((state) => state.store.language);

  return (
    <>
      <OverLay handleClose={closeCartDisp} arg={"transparent"} />
      <div className="cart-displayer">
        <div>
          {isLoggedIn ? (
            loading & !error ? (
              <Loading />
            ) : !loading & !error ? (
              <>
                {products.length > 0 ? (
                  <>
                    <span
                      className="close-cart"
                      id="modalCloseBtn"
                      onClick={closeCartDisp}
                    >
                      &times;
                    </span>
                    {products.map((result, key) => (
                      <div key={key} className="cart-item">
                        {loading ? (
                          <Loading />
                        ) : (
                          <SearchDisplayer
                            handleClose={closeCartDisp}
                            product={result}
                          />
                        )}
                        <div
                          className="action-cart"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <button
                            onClick={() => handleRemoveCart(result)}
                            className="btn btn-danger"
                          >
                            &minus; {langType === "om" ? "Balleessi" : "Remove"}
                          </button>
                        </div>
                      </div>
                    ))}
                    <div>
                      <h4>Total price: {totalPrice}</h4>
                    </div>
                  </>
                ) : (
                  <div>
                    {langType === "om"
                      ? "Gaariin Kee duwwaadha"
                      : "You have no product in a cart"}
                  </div>
                )}
              </>
            ) : (
              <div className="alert alert-warning">
                Error while fetching data
              </div>
            )
          ) : (
            <>
              <GenericModal
                isOpen={isLoginModalOpen}
                onClose={closeLoginModal}
                children={<Login />}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}
