// EditUserForm.js
import React, { useState } from "react";
import GenericModal from "../../shared/GenericModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { APIURL } from "../../asset/host";

export const EditButton = ({ userData }) => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <span
        className=" p-3 cursor-pointer flex items-center hover:text-primary"
        onClick={() => setOpenModal(true)}
      >
        <FontAwesomeIcon icon={faPen} /> Edit
      </span>
      <GenericModal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        children={
          <EditUserForm
            userData={userData}
            onClose={() => setOpenModal(false)}
          />
        }
      />
    </>
  );
};

const EditUserForm = ({ userData, onClose }) => {
  const [usernameAvailable, setUsernameAvailable] = useState();
  const [startAlerting, setStartAlerting] = useState(false);
  const [error, setError] = useState(null);
  const [msg, setmsg] = useState(null);
  const [formValues, setFormValues] = useState({
    email: userData.email || "",
    fullName: userData.fullName || "",
    phoneNumber: userData.phoneNumber || "",
    sex: userData.sex || "",
    userType: userData.userType || "",
    username: userData.username || "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put(`${APIURL}/api/users`, formValues, {
        withCredentials: true,
      })
      .then((res) => {
        setmsg(res.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        setError(err.response.data.error);
        // console.log(err.response.data.error);
      });
    // console.log("Form submitted with values:", formValues);
  };

  const handleUsernameChange = (event) => {
    setStartAlerting(true);
    if (event.target.value) {
      axios
        .post(`${APIURL}/api/users/checkUsernameAvailability`, {
          username: event.target.value,
        })
        .then((res) => {
          setUsernameAvailable(false);
          if (res.data.available) {
            setUsernameAvailable(true);
          } else {
            setUsernameAvailable(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setStartAlerting(false);
    }
    handleChange(event);
  };
  return (
    <form
      onSubmit={handleSubmit}
      className=" flex-col items-center p-4 justify-center max-w-md mx-auto mt-8"
    >
      {error !== null ? (
        <div className="alert alert-danger">{error}</div>
      ) : msg !== null ? (
        <div className="alert alert-success">{msg}</div>
      ) : (
        <></>
      )}
      <div className="m-auto mb-4">
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formValues.email}
          onChange={handleChange}
          placeholder="Enter email"
          className="border p-2 w-full rounded"
        />
      </div>
      <div className="m-auto mb-4">
        <label htmlFor="fullName">Full Name:</label>
        <input
          type="text"
          id="fullName"
          name="fullName"
          value={formValues.fullName}
          onChange={handleChange}
          placeholder="Enter full name"
          className="border p-2 w-full rounded"
        />
      </div>

      <div className="m-auto mb-4">
        <label htmlFor="phoneNumber">Phone Number:</label>
        <input
          type="tel"
          id="phoneNumber"
          name="phoneNumber"
          value={formValues.phoneNumber}
          onChange={handleChange}
          placeholder="Enter phone number"
          className="border p-2 w-full rounded"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="sex">Sex:</label>
        <select
          id="sex"
          name="sex"
          value={formValues.sex}
          onChange={handleChange}
          className="border p-2 w-full rounded"
        >
          <option value="">Select sex</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
        </select>
      </div>

      <div className="mb-4">
        <label htmlFor="userType">User Type:</label>
        <select
          id="userType"
          name="userType"
          value={formValues.userType}
          onChange={handleChange}
          className="border p-2 w-full rounded"
        >
          <option value="">User type</option>
          <option value="seller">seller</option>
          <option value="buyer">buyer</option>
        </select>
      </div>
      <div className="m-auto mb-4">
        <label htmlFor="username">Username:</label>
        <input
          type="text"
          id="username"
          name="username"
          value={formValues.username}
          onChange={handleUsernameChange}
          placeholder="Enter username"
          className="border p-2 w-full rounded"
        />{" "}
        <br />
        {startAlerting ? (
          <>
            {" "}
            {usernameAvailable ? (
              <span className="alert-success">Available</span>
            ) : (
              <sp className="alert-danger">Not available</sp>
            )}
            <br /> <br />
          </>
        ) : (
          <></>
        )}
      </div>
      <button
        type="submit"
        onSubmit={handleSubmit}
        className="btn btn-secondary text-white p-2"
      >
        Save Changes
      </button>
    </form>
  );
};

export default EditUserForm;
