// Breadcrumbs.js
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { pathLang } from "../asset/language";
import { useSelector } from "react-redux";

const Breadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const langType = useSelector((state) => state.store.language);
  return (
    <div className=" bg-primary-light bg-opacity-15 p-1 sm:pl-3 flex items-center">
      <Link to="/" className="no-underline  hover:underline">
        <FontAwesomeIcon icon={faHome} />
      </Link>
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
        return (
          <span className=" link" key={index}>
            <FontAwesomeIcon icon={faChevronRight} className="mx-1 text-sm" />
            <Link
              to={routeTo}
              className=" no-underline text-gray-700 hover:underline"
            >
              {pathLang[langType][name] || name}
            </Link>
          </span>
        );
      })}
    </div>
  );
};

export default Breadcrumbs;
